
const date = {

    getMonths: function(text){

        var months = {1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'};

        text = text || false;

        if(!text){
            return months;
        }
        return months[text];
    },
    
    formattedDate: function(date, short_year, with_time){

        short_year = short_year || false;
        with_time = with_time || false;

        if(typeof date === 'string'){
            var _date = date.split('-');
            var month = this.getMonths(parseInt(_date[1]));

            var year = _date[0];
            if(short_year){
                year = year.substring(2, 4);
            }
            return _date[2] + ' ' + month + ', ' + year;
        
        }else if(typeof date === 'object'){

            var _date = date.getDate();
            var _month = date.getMonth();

            var final_date = _date + ' ' + this.getMonths(parseInt(_month) + 1) + ', ' + date.getFullYear();

            if(!with_time){

                return final_date;
            }
            return final_date + ' ' + this.formatTime(date);
        }

        var _date = date.day + ' ' + this.getMonths(date.month) + ', ' + date.year;
        return _date;
    },

    formatTime: function(date){

        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    },

    formatData: function(date, with_time){

        with_time = with_time || false;

        if(typeof date === 'string'){
            var _date = date.split('-');

            return _date[0] + '-' + _date[1] + '-' + _date[2];
        
        }else if(typeof date === 'object'){

            var _date = date.getDate();
            var _month = date.getMonth();

            var final_date = date.getFullYear() + '-' + (parseInt(_month) + 1) + '-' + _date;

            if(!with_time){

                return final_date;
            }
            return final_date + ' ' + date.getHours() + ':' + date.getMinutes() + ':00';
        }

        var _date = date.day + ' ' + this.getMonths(date.month) + ', ' + date.year;
        return _date;
    }
}

export default date;