import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import Bannerwithform from '../../components/sections/Bannerwithform';

import Main from '../../components/Main';

class TermsConditions extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <Main page="terms_of_use" className="subject-choices">
                <Bannerwithform title="Terms of Use" />

                <div className="section subject-choices-section">
                    <Container>
                        <div className="main-section">
                            
                            <p>These Terms of Use (“Terms”, “Terms of Use”) govern your relationship between you and Study Feeds (“Study Feeds”, “us”, “we”, or “our”), a Sole Proprietorship in relation to the use of https://testas.studyfeeds.com and https://www.studyfeeds.com website (the “Service”) as operated by Study Feeds.</p>

                            <p>These Terms of Use are exclusively applicable to the relationship between Users and Study Feeds and are not applicable to the relationship with Subject-matter experts, which is governed by specific, different, Terms and Conditions.</p>

                            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service, except Test Authors. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the Terms then you may not access the Service.</p>

                            <h5><strong>A. Eligibility</strong></h5>

                            <p>In order to use the Service, you must:</p>

                            <ol>
                                <li>be at least eighteen (18) years old and able to legally undergo contractual obligations;</li>
                                <li>complete the registration process;</li>
                                <li>agree to these Terms;</li>
                                <li>provide true, complete, and up-to-date contact and billing information; and</li>
                                <li>not be – either individually or as part of a group, entity or state that prohibit the use of this Service</li>
                            </ol>

                            <p>By using the Service, you represent and warrant that you meet all the requirements listed above, and that you won’t use the Service in a way that violates any laws or regulations. Note that by representing and warranting, you are making a legally enforceable promise.</p>

                            <p>Study Feeds may refuse service, close accounts of any users, and change eligibility requirements at any time.</p>

                            <h5><strong>B. Free Trial</strong></h5>

                            <p>Study Feeds may, at its sole discretion, offer a Subscription with a free trial for a limited period of time (“Free Trial”).</p>

                            <p>When your free trial expires, you will not be charged by Study Feeds.</p>

                            <div>
                                <p>At any time and without notice, Study Feeds reserves the right to</p>
                                <ul>
                                    <li>modify the terms of use of the Free Trial offer, or</li>
                                    <li>(ii) cancel such Free Trial offer.</li>
                                </ul>
                            </div>

                            <h5><strong>C. Fee Changes</strong></h5>

                            <p>Study Feeds, in its sole discretion and at any time, may modify the Test fees for the Online Test which will be applicable from the date of modifications.</p>

                            <h5 id="refund"><strong>D. Refunds &amp; Cancellations</strong></h5>

                            <p>Certain refund requests for Tests may be considered by Study Feeds on a case-by-case basis and granted at sole discretion of Study Feeds. Cancellation request can be placed before attempting the Test. Please note, your money will be refunded after deducting a payment gateway transaction fee i.e., 2% of total amount paid.</p>

                            <h5><strong>E. Content licensing and intellectual property</strong></h5>

                            <p>All Tests and their questions and answers are developed by Study Feeds , as the creator of the Content retain any and all intellectual property rights on Said Content. As the sole right owner, we do not allow any user to copy, share or re-distribute them without written permission of Study Feeds. Violation of data protection and privacy rights, copyrights, or other intellectual property rights, contractual rights, or any other rights of any third person are subject to legal action as per law.</p>

                            <h5><strong>F. Authorized use of the Service</strong></h5>

                            <p>You may only use the Service for lawful purposes and in a manner consistent with the nature and purpose of TestAS Online Tests offered by Study Feeds.</p>

                            <p>Unauthorized use of the Service may be a criminal offense and/or give rise to a claim for damages.</p>

                            <h5><strong>G. Accounts</strong></h5>

                            <p>When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in termination of your account on our Service.</p>

                            <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</p>

                            <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>

                            <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>

                            <h5><strong>H. Intellectual Property on the Service</strong></h5>

                            <p>The Service and its original content (excluding Content provided by users), features, and functionality are and shall remain the exclusive property of Study Feeds and its licensors. The Service is protected by copyright, trademark, and other laws of both the India and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Study Feeds. You agree that Study Feeds shall hold the exclusive property rights to the (aggregated and anonymized) test scores and may use these for performing and improving its Services (e.g. benchmarking of scores).</p>

                            <h5><strong>I. Links To Other Web Sites</strong></h5>

                            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Study Feeds.</p>

                            <p>Study Feeds has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that Study Feeds shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

                            <p>We strongly advise you to read the terms of use and privacy policies of any third-party web sites or services that you visit.</p>

                            <h5><strong>J. Data Protection</strong></h5>

                            <p>You acknowledge that information about you and the Content posted will be collected, held, and used by us in accordance with our&nbsp;<u>Privacy Policy</u>.</p>

                            <h5><strong>K. Termination</strong></h5>

                            <p>We may terminate or suspend your account, without prior notice or liability, for any reason, including without limitation if you breach the Terms.</p>

                            <p>Upon termination, your right to use the Service shall immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>

                            <h5><strong>L. Limitation Of Liability</strong></h5>

                            <p>In no event shall Study Feeds, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose. Such limitations shall not apply if the damages are the result of a deliberate act or gross negligence on the part of Study Feeds or its senior management.</p>

                            <h5><strong>M. Disclaimer</strong></h5>

                            <p>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.</p>

                            <p>Study Feeds its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>

                            <h5><strong>N. Governing Law</strong></h5>

                            <p>These Terms shall be governed and construed in accordance with the laws of the India, without regard to its conflict of law provisions.</p>

                            <p>All disputes between Study Feeds and you (whether or not such dispute involves a third party) shall be submitted exclusively to the competent court of law in New Delhi, the India.</p>

                            <p>Our failure to enforce any right or provision of these Terms shall not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms shall remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>

                            <h5><strong>O. Changes</strong></h5>

                            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change shall be determined at our sole discretion.</p>

                            <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>

                            <h5><strong>P. Partial invalidity</strong></h5>

                            <p>If, at any time, any provision of the Terms is or becomes illegal, invalid or unenforceable in any respect under any law of any jurisdiction, neither the legality, validity or enforceability of the remaining provisions nor the legality, validity or enforceability of such provision under the law of any other jurisdiction shall in any way be affected or impaired.</p>

                            <h5><strong>R. Contact Us</strong></h5>

                            <p>If you have any questions about these Terms, please&nbsp;<u>contact us</u>.</p>

                        </div>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default TermsConditions;