import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import Bannerwithform from '../components/sections/Bannerwithform';

import Main from '../components/Main';

class AfterStudy extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    createData = (type1, type2, type3) => {
        return { type1, type2, type3 };
    }

    render () {

        const rows = [
            this.createData('Chemical/Pharmaceuticals', '51,163', '56,950'),
            this.createData('Vehicle/Automotive', '50,275', '52,560'),
            this.createData('Electrical/Electronics', '48,150', '51,600'),
            this.createData('Machinery & Equipment', '46,299', '49,440'),
            this.createData('Energy/Power', '45,500', '48,000'),
            this.createData('IT', '47,660', '48,070'),
            this.createData('Engineering & Planning Office', '40,200', '43,793'),
            this.createData('Construction', '41,500', '43,793'),
        ];

        return (

            <Main page="test_as_main" className="after-study">
                <Bannerwithform page="enquiry" title="After Studies" />

                <div className="section">
                    <Container>
                        
                        <div className="main-section">

                            <div className="list-heading">Please take note of the following regulations regarding opportunities post completion of your studies in Germany:</div>
                            <List className="options-listing" component="nav" aria-label="main options">
                                <ListItem>
                                    <ListItemIcon>1.</ListItemIcon>
                                    <ListItemText>Germany offers 18 months job search visa after completion of studies.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>2.</ListItemIcon>
                                    <ListItemText>This job search visa is valid throughout the Schengen region. (https://en.wikipedia.org/wiki/Schengen_Area)</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>3.</ListItemIcon>
                                    <ListItemText>In order to obtain this job search visa the student needs to show proof regarding his financial stability. This has to be proven by a blocked account.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>4.</ListItemIcon>
                                    <ListItemText>Job opportunities are majorly available via Job fairs and online portals.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>5.</ListItemIcon>
                                    <ListItemText>After getting a job, the job search visa will be converted either into a Work Permit or an EU Blue Card.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>6.</ListItemIcon>
                                    <ListItemText>As per the latest guidelines issued by the Federal Government in 2022, professionals with a gross annual income of 56,400 Euros become eligible to apply for the EU Blue card. However, the professionals working in the fields of mathematics, IT, natural sciences, engineering and human medicine become eligible at a minimum gross annual salary of 43,992 Euros.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>7.</ListItemIcon>
                                    <ListItemText>The EU Blue card is valid for 3 months in addition to the work contract tenure and is limited to a 4 year period as per the Government norms.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>8.</ListItemIcon>
                                    <ListItemText>A ‘Settlement Permit’ can be obtained after 21/24 months (depending on the state) if the student holds a German language certificate at B1 level along with a German University degree. It takes 33 months if the German language skills are at A1 level.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>9.</ListItemIcon>
                                    <ListItemText>A professional becomes eligible for citizenship if s/he has been living in Germany for at least 8 years, holds gainful employment and is not relying on social benefits. Additionally, the person needs to clear the test designed by the Government for gaining citizenship.</ListItemText>
                                </ListItem>
                            </List>

                            <div className="note yellow-bg"><b>Kindly note:</b> The given information is a compilation of < b>basics</b> related to the post study opportunities in Germany. Each one of the transitions has a separate set of rules which needs to be followed as and when upgrading the residence type.</div>
                            
                        </div>
                    </Container>
                </div>

                <div className="section salary-section">
                    <Container>
                        <div className="section-heading">Starting Salaries</div>

                        <TableContainer component={Paper}>
                            <Table className="salaries-table" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Domain</TableCell>
                                        <TableCell>2017 (in Euros)</TableCell>
                                        <TableCell>2019 (in Euros)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.type1}>
                                            <TableCell component="th" scope="row">
                                                {row.type1}
                                            </TableCell>
                                            <TableCell>{row.type2}</TableCell>
                                            <TableCell>{row.type3}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className="ref mt-2">Source: www.ingenieur.de</div>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default AfterStudy;