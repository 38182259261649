import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { connect } from 'react-redux';

import { Container, Button, Menu, MenuItem, Avatar, IconButton, Drawer, Popper, Grow, MenuList, ClickAwayListener } from '@material-ui/core';
import {User} from '../actions/user';

import logo from '../assets/images/logo.png';

import { AccountCircle, ExitToApp, Person } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';

class AppHeader extends Component { 
    constructor(props) {
        super();
        this.state = {
            account_token: false,

            login: false,
            user: false,

            profile_menu: false,
            redirect_to_account: false,

            update_profile: false,

            mobile_menu: false,

            submenu_ele: false,

            is_mobile: window.matchMedia("(max-width: 767px)").matches
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('sf_student_token');
        var user = localStorage.getItem('user');
        
        if(account_token){
            this.setState({account_token: account_token});

            var _user = JSON.parse(user);
            this.setState({user: _user});

            this.props.User(_user);

            this.setState({login: true});
        }
    }

    headerProfileImage = () => {

        if(this.props.user && this.props.user.profile_image_preview != ''){
                
            // return <Avatar style={{width:25, height:25}} alt={this.props.user.first_name} src={this.props.user.profile_image_preview} />
            return <Avatar style={{width:25, height:25}}>A</Avatar>
        }else{
            
            return (
                <Avatar>
                    <AccountCircle />
                </Avatar>
            )
        }
    }

    headerUsername = () => {

        if(this.props.user && this.props.user.name != ''){
                
            return this.props.user.name
        }else{
            
            return 'No User'
        }
    }

    renderMenu = () => {

        return (
            <>
                <ul className="menu">
                    <li className="nav-link">
                        <Link to="/">
                            <Button>TestAS Online Test</Button>
                        </Link>
                    </li>
                    <li className="nav-link has-child">
                        {/* <Button onClick={(e) => {

                            if(!this.state.submenu_ele){
                            
                                this.setState({submenu_ele: e.currentTarget})
                            }else{

                                this.setState({submenu_ele: false})
                            }
                        }}>TestAS Preparatory Course</Button> */}

                        <Link to="/testas">
                            <Button>TestAS Preparatory Course</Button>
                        </Link>

                        <MenuList style={{display: 'none'}}>
                            <MenuItem>
                                <Link to="/about-testas">About TestAS</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/german-language">German Language</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/subject-choices">Subject Choices</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/during-study-in-germany">During studies in Germany</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/after-study">After Studies</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/previous-procedure">Previous Procedure</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/blocked-account">Blocked Account</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/about-studyfeeds">About Studyfeeds</Link>
                            </MenuItem>
                        </MenuList>

                        {this.state.is_mobile &&
                        
                            <ul>
                                <li>
                                    <Link to="/about-testas">About TestAS</Link>
                                </li>
                                <li>
                                    <Link to="/german-language">German Language</Link>
                                </li>
                                <li>
                                    <Link to="/subject-choices">Subject Choices</Link>
                                </li>
                                <li>
                                    <Link to="/during-study-in-germany">During studies in Germany</Link>
                                </li>
                                <li>
                                    <Link to="/after-study">After Studies</Link>
                                </li>
                                <li>
                                    <Link to="/previous-procedure">Previous Procedure</Link>
                                </li>
                                <li>
                                    <Link to="/blocked-account">Blocked Account</Link>
                                </li>
                                <li>
                                    <Link to="/about-studyfeeds">About Studyfeeds</Link>
                                </li>
                            </ul>
                        }
                    </li>
                    {/* <li className="nav-link">
                        <Link to="/german-language">
                            <Button>German Language</Button>
                        </Link>
                    </li>
                    <li className="nav-link">
                        <Link to="/">
                            <Button>Why Us</Button>
                        </Link>
                    </li> */}
                    <li className="nav-link">
                        <Link to="/contact">
                            <Button>Contact</Button>
                        </Link>
                    </li>
                </ul>

                {!this.state.is_mobile &&
                
                    <Popper
                        onClose={() => {
                            this.setState({submenu_ele: false})
                        }}
                        className="navigation-submenu"
                        anchorEl={this.state.submenu_ele}
                        open={this.state.submenu_ele}
                        placement="bottom"
                        disablePortal={false}
                        modifiers={{
                            flip: {
                                enabled: true,
                            },
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: 'scrollParent',
                            },
                        }}
                    >
                        <MenuList>
                            <MenuItem>
                                <Link to="/testas">TestAS</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/about-testas">About TestAS</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/german-language">German Language</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/subject-choices">Subject Choices</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/during-study-in-germany">During studies in Germany</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/after-study">After Studies</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/previous-procedure">Previous Procedure</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/blocked-account">Blocked Account</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/about-studyfeeds">About Studyfeeds</Link>
                            </MenuItem>
                        </MenuList>
                    </Popper>
                }
            </>
        )
    }

    render () {

        if(this.state.redirect_to_account){
            return <Redirect to='/account'/>;
        }

        if(this.state.update_profile){
            return <Redirect to='/account/profile-update'/>;
        }

        return (

            <>
                <React.Fragment>
                    <div className="header">
                        <Container>
                            <div className="header-block">
                                <div className="first-block">
                                    <Link to="/"><img className="logo" src={logo} alt="Logo" /></Link>
                                </div>

                                {!this.state.is_mobile
                                    ?

                                        <div className="second-block">
                                            <div className="navigation">
                                                {this.renderMenu()}
                                            </div>
                                        </div>
                                    :
                                        <Drawer
                                            open={this.state.mobile_menu}
                                            onClose={() => {
                                                this.setState({mobile_menu: false})
                                            }}
                                            anchor="top"
                                        >
                                            <div className="navigation mobile-nav">
                                                {this.renderMenu()}
                                            </div>
                                        </Drawer>
                                }

                                <div className="third-block">

                                    {this.state.is_mobile
                                        ?
                                            <div className="links">

                                                <ul>

                                                    {this.state.login
                                                        ?
                                                            <li>

                                                                <Link to="/account">
                                                                    <IconButton>
                                                                        <Person />
                                                                    </IconButton>
                                                                </Link>
                                                            </li>
                                                        :
                                                            <li>

                                                                <Link to="/signin">
                                                                    <IconButton>
                                                                        <Person />
                                                                    </IconButton>
                                                                </Link>
                                                            </li>
                                                    }

                                                    <li>
                                                        <IconButton onClick={() => {

                                                            this.setState({mobile_menu: true})
                                                        }}>
                                                            <MenuIcon />
                                                        </IconButton>
                                                    </li>
                                                </ul>
                                            </div>
                                        :

                                            <div className="links">
                                                
                                                {this.state.login
                                                    ?
                                                        <>

                                                            <Button className="profile-menu" aria-controls="header_menu" aria-haspopup="true" onClick={(e) => {
                                                                this.setState({profile_menu: e.target})
                                                            }}>
                                                                {this.headerProfileImage()}
                                                                <span className="ml-10">{this.headerUsername()}</span>
                                                            </Button>
                                                            <Menu
                                                                anchorEl={this.state.profile_menu}
                                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                                id="header_menu"
                                                                keepMounted
                                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                                open={this.state.profile_menu != false ? true : false}
                                                                onClose={() => {
                                                                    this.setState({profile_menu: false})
                                                                }}
                                                            >
                                                                <MenuItem style={{width:250, padding:0}}>
                                                                    <Link style={{display:'block', padding:15, width:'100%'}} to="/account">Account</Link>
                                                                </MenuItem>
                                                                <MenuItem style={{width:250, padding:0}}>
                                                                    <Link style={{display:'block', padding:15, width:'100%'}} to="/account/tests">Buy TestAS Test</Link>
                                                                </MenuItem>
                                                            </Menu>
                                                            <Link to="/logout" className="link">
                                                                <ExitToApp />
                                                            </Link>
                                                        </>
                                                    :
                                                        <>
                                                            <Link to="/signin" className="btn btn-blk">Login</Link>
                                                            <Link to="/signup" className="btn btn-red">Signup</Link>
                                                        </>
                                                }

                                            </div>
                                    }
                                </div>
                            </div>
                            
                        </Container>
                    </div>
                </React.Fragment>
            </>

        )
    }
}

const mapStateToProps = state => {
	return {
        user: state.user.user
	}
}

export default connect(mapStateToProps, { User } )(AppHeader);