import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { FormControl, TextField, Button, Grid, Select, InputLabel, MenuItem, FormHelperText } from '@material-ui/core';

import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';

import Loader from '../components/Loader';
import Api from '../api/Api';

import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Main from '../components/Main';

import ValidatorHelper from '../helpers/ValidatorHelper';
import DateHelper from '../helpers/DateHelper';

class Signin extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,
            states_loading: false,

            success_message: false,
            error_message: false,

            row_id: false,

            enquiry: false,

            countries: [],
            states_list: [],

            country_id: '',
            selected_state: '',

            first_name: '',
            last_name: '',
            email: '',
            mobile: '',

            passing_year_12: null,
            passing_year_12_selected: '',

            passing_year_10: null,
            passing_year_10_selected: '',

            first_name_error: '',
            last_name_error: '',
            email_error: '',
            mobile_error: '',
            subjects_12_error: '',
            percentage_12_error: '',
            passing_year_12_error: '',
            subjects_12_error: '',
            percentage_10_error: '',
            passing_year_10_error: '',
            country_error: '',
            state_error: '',
            preferred_course_error: '',
            percentage_12_error: '',
            percentage_12_error: '',

            redirect: false

        }

        this.form_container = React.createRef();
    }

    componentDidMount = () => {

        var row_id = this.props.request_id;

        if(row_id == ''){

            row_id = localStorage.getItem('enquiry_id');
        }

        if(row_id != ''){

            this.setState({row_id: row_id});
            this.loadEnquery(row_id);
        }

        this.countriesList();
    }

    render () {

        if(this.state.redirect){

            return <Redirect to='/thankyou' />;
        }

        return (

            <Main page="eligibility-form">
                
                <div className="center-align-page">
                    <div className="container">
                        <div className="align-center">
                            
                            <div className="section-heading" ref={this.form_container}>Eligibility Form</div>

                            <div className="block-wrapper">

                                {this.state.success_message &&
                                    <Alert severity="success" className="mb-30">
                                        <AlertTitle>Success</AlertTitle>
                                        {this.state.success_message}
                                    </Alert>
                                }

                                {this.state.error_message &&
                                    <Alert severity="error" className="mb-30" onClose={() => {this.setState({error_message: false})}}>
                                        <AlertTitle>Error</AlertTitle>
                                        {this.state.error_message}
                                    </Alert>
                                }
                                
                                {!this.state.success_message &&
                                    <form className="form" name="login_form" onSubmit={this.onFinish.bind(this)} autoComplete="off">
                                        
                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true}>
                                                    <TextField
                                                        name="first_name"
                                                        label="First Name"
                                                        size="small"
                                                        helperText={this.state.first_name_error}
                                                        error={this.state.first_name_error != '' ? true : false}
                                                        autoComplete="off"
                                                        value={this.state.first_name}
                                                        onChange={(e) => {

                                                            this.setState({first_name: e.target.value})
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true}>
                                                    <TextField
                                                        name="last_name"
                                                        label="Last Name"
                                                        size="small"
                                                        helperText={this.state.last_name_error}
                                                        error={this.state.last_name_error != '' ? true : false}
                                                        autoComplete="off"
                                                        value={this.state.last_name}
                                                        onChange={(e) => {

                                                            this.setState({last_name: e.target.value})
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true}>
                                                    <TextField
                                                        name="email"
                                                        label="Email"
                                                        size="small"
                                                        helperText={this.state.email_error}
                                                        error={this.state.email_error != '' ? true : false}
                                                        autoComplete="off"
                                                        value={this.state.email}
                                                        onChange={(e) => {

                                                            this.setState({email: e.target.value})
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true}>
                                                    <TextField
                                                        name="mobile"
                                                        label="Mobile Number"
                                                        size="small"
                                                        helperText={this.state.mobile_error}
                                                        error={this.state.mobile_error != '' ? true : false}
                                                        autoComplete="off"
                                                        value={this.state.mobile}
                                                        onChange={(e) => {

                                                            this.setState({mobile: e.target.value})
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true}>
                                                    <TextField
                                                        name="percentage_12"
                                                        label="Gross Percentage in 12th? (Achieved/Expected)"
                                                        size="small"
                                                        helperText={this.state.percentage_12_error}
                                                        error={this.state.percentage_12_error != '' ? true : false}
                                                        autoComplete="off"
                                                        placeholder="e.g. 82,87"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                                <Grid item sm={12}>

                                                    <FormControl fullWidth={true} error={this.state.passing_year_12_error != '' ? true : false}>
                                                        <DatePicker
                                                            format="dd/MM/yyyy"
                                                            label="Year of Passing 12th"
                                                            value={this.state.passing_year_12}
                                                            onChange={(e) => {
                                                                var date = new Date(e);

                                                                this.setState({passing_year_12: DateHelper.formattedDate(date, true, false)});
                                                                this.setState({passing_year_12_selected: DateHelper.formatData(date, false)});
                                                            }}
                                                            error={this.state.passing_year_12_error != '' ? true : false}
                                                        />

                                                        {this.state.passing_year_12_error != '' &&

                                                            <FormHelperText>{this.state.passing_year_12_error}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true} size="small" error={this.state.subjects_12_error != '' ? true : false}>
                                                
                                                    <InputLabel id="subjects_12">Subjects in 12th</InputLabel>
                                                    <Select
                                                        name="subjects_12"
                                                        error={this.state.subjects_12_error != '' ? true : false}
                                                        labelId="subjects_12"
                                                        id="subjects_12_select"
                                                    >
                                                        <MenuItem value=''>--</MenuItem>
                                                        <MenuItem value="PCM">PCM</MenuItem>
                                                        <MenuItem value="PCB">PCB</MenuItem>
                                                        <MenuItem value="Commerce">Commerce</MenuItem>
                                                        <MenuItem value="Humanities">Humanities</MenuItem>
                                                        <MenuItem value="Others">Others</MenuItem>
                                                    </Select>

                                                    {this.state.subjects_12_error != '' &&

                                                        <FormHelperText>{this.state.subjects_12_error}</FormHelperText>
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true}>
                                                    <TextField
                                                        name="additional_subjects_12"
                                                        label="Additional subjects in 12th"
                                                        size="small"
                                                        autoComplete="off"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true}>
                                                    <TextField
                                                        name="percentage_10"
                                                        label="Gross Percentage in 10th? (Achieved/Expected)"
                                                        size="small"
                                                        helperText={this.state.percentage_10_error}
                                                        error={this.state.percentage_10_error != '' ? true : false}
                                                        autoComplete="off"
                                                        placeholder="e.g. 82,87"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                                <Grid item sm={12}>

                                                    <FormControl fullWidth={true} error={this.state.passing_year_10_error != '' ? true : false}>
                                                        <DatePicker
                                                            format="dd/MM/yyyy"
                                                            label="Year of Passing 10th"
                                                            value={this.state.passing_year_10}
                                                            onChange={(e) => {
                                                                var date = new Date(e);

                                                                this.setState({passing_year_10: DateHelper.formattedDate(date, true, false)});
                                                                this.setState({passing_year_10_selected: DateHelper.formatData(date, false)});
                                                            }}
                                                            error={this.state.passing_year_10_error != '' ? true : false}
                                                        />

                                                        {this.state.passing_year_10_error != '' &&

                                                            <FormHelperText>{this.state.passing_year_10_error}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true}>
                                                    <TextField
                                                        name="name_of_school"
                                                        label="Name of School"
                                                        size="small"
                                                        autoComplete="off"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>

                                                <FormControl fullWidth={true} size="small" component="fieldset" error={this.state.country_error != '' ? true :false}>

                                                    {this.state.countries && this.state.countries.length > 0
                                                        ?
                                                            <Autocomplete
                                                                margin="normal"
                                                                options={this.state.countries}
                                                                getOptionLabel={(option) => {
                                                                    return option.name
                                                                }}
                                                                autoComplete={false}
                                                                disableClearable
                                                                renderInput={(params) => <TextField size="small" {...params} error={this.state.country_error != '' ? true : false} label="Country *" />}
                                                                onChange={(option, value) => {
                                                                    this.setState({country_id: value.iso});
                                                                    this.statesList(value.iso);
                                                                }}
                                                            />
                                                        :
                                                            null
                                                    }
                                                    <FormHelperText>{this.state.country_error}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>

                                                <FormControl fullWidth={true} size="small" error={this.state.state_error != '' ? true : false}>
                                            
                                                    {this.state.states_list.length == 0
                                                        ?
                                                            
                                                            <TextField name="state" id="state" label="State" size="small" autoComplete="off" error={this.state.state_error != '' ? true :false} helperText={this.state.state_error}/>
                                                        :
                                                            <>
                                                                <InputLabel htmlFor="state_id">State</InputLabel>
                                                                <Select name="state_id" label="State" size="small" error={this.state.state_error != '' ? true : false} helperText={this.state.state_error} onChange={() => {
                                                                    this.setState({state_error: false})
                                                                }}>

                                                                    {this.state.states_list.length > 0 &&

                                                                        this.state.states_list.map((_state) => {

                                                                            return <MenuItem key={"state_" + _state.id} value={_state.id}>{_state.name}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>

                                                                <FormHelperText>{this.state.state_error}</FormHelperText>
                                                            </>
                                                    }

                                                    <Loader loading={this.state.states_loading} />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true}>
                                                    <TextField
                                                        name="city"
                                                        label="City"
                                                        size="small"
                                                        autoComplete="off"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true} size="small" error={this.state.preferred_course_error != '' ? true : false}>
                                                
                                                    <InputLabel id="preferred_course">Preferred Course</InputLabel>
                                                    <Select
                                                        name="preferred_course"
                                                        error={this.state.preferred_course_error != '' ? true : false}
                                                        labelId="preferred_course"
                                                        id="preferred_course_select"
                                                    >
                                                        <MenuItem value="">---</MenuItem>
                                                        <MenuItem value="Mechanical Engineering (Only Winter Semester)">Mechanical Engineering (Only Winter Semester)</MenuItem>
                                                        <MenuItem value="Automotive Engineering (Only Winter Semester)">Automotive Engineering (Only Winter Semester)</MenuItem>
                                                        <MenuItem value="Computer Science">Computer Science</MenuItem>
                                                        <MenuItem value="Human Medicine with 80% and above">Human Medicine with 80% and above</MenuItem>
                                                        <MenuItem value="Dentistry with 80% and above">Dentistry with 80% and above</MenuItem>
                                                        <MenuItem value="Neurosciences with 70% and above">Neurosciences with 70% and above</MenuItem>
                                                        <MenuItem value="Mathematics">Mathematics</MenuItem>
                                                        <MenuItem value="Electrical Engineering">Electrical Engineering</MenuItem>
                                                        <MenuItem value="Business Mathematics">Business Mathematics</MenuItem>
                                                        <MenuItem value="Industrial Engineering (Only Winter Semester)">Industrial Engineering (Only Winter Semester)</MenuItem>
                                                        <MenuItem value="Civil Engineering (Only Winter Semester)">Civil Engineering (Only Winter Semester)</MenuItem>
                                                        <MenuItem value="Transportation Engineering (Only Winter Semester)">Transportation Engineering (Only Winter Semester)</MenuItem>
                                                        <MenuItem value="Safety Engineering (Only Winter Semester)">Safety Engineering (Only Winter Semester)</MenuItem>
                                                        <MenuItem value="Physics">Physics</MenuItem>
                                                        <MenuItem value="Chemistry">Chemistry</MenuItem>
                                                        <MenuItem value="Business Administration">Business Administration</MenuItem>
                                                        <MenuItem value="Business Information Systems">Business Information Systems</MenuItem>
                                                        <MenuItem value="Philosophy (Only Winter Semester)">Philosophy (Only Winter Semester)</MenuItem>
                                                        <MenuItem value="Information and Media Technology">Information and Media Technology</MenuItem>
                                                        <MenuItem value="Musicology (Only Winter Semester) (Additional assessment test)">Musicology (Only Winter Semester) (Additional assessment test)</MenuItem>
                                                        <MenuItem value="Printing and Media Technology (Only Winter Semester)">Printing and Media Technology (Only Winter Semester)</MenuItem>
                                                        <MenuItem value="Industrial + Electrical Engineering (Only Winter Semester) (2 subject bachelor)">Industrial + Electrical Engineering (Only Winter Semester) (2 subject bachelor)</MenuItem>
                                                        <MenuItem value="Interior Designing (Only Winter Semester) (Additional assessment test)">Interior Designing (Only Winter Semester) (Additional assessment test)</MenuItem>
                                                        <MenuItem value="Architecture (Only Winter Semester)">Architecture (Only Winter Semester)</MenuItem>
                                                        <MenuItem value="Economics">Economics</MenuItem>
                                                        <MenuItem value="Digital Medical Technology (Only Winter Semester) with 80% and above">Digital Medical Technology (Only Winter Semester) with 80% and above</MenuItem>
                                                        <MenuItem value="Digital Public Health (Only Winter Semester) with 80% and above">Digital Public Health (Only Winter Semester) with 80% and above</MenuItem>
                                                        <MenuItem value="Biomedical Technology (Only Winter Semester) with 80% and above">Biomedical Technology (Only Winter Semester) with 80% and above</MenuItem>
                                                        <MenuItem value="Social Work (Only Winter Semester) with 70% and above">Social Work (Only Winter Semester) with 70% and above</MenuItem>
                                                        <MenuItem value="German linguistics (Only Winter Semester) with 65% and above">German linguistics (Only Winter Semester) with 65% and above</MenuItem>
                                                        <MenuItem value="English Linguistics (Only Winter Semester) with 65% and above">English Linguistics (Only Winter Semester) with 65% and above</MenuItem>
                                                        <MenuItem value="Geography(Only Winter Semester) with 65% and above">Geography(Only Winter Semester) with 65% and above</MenuItem>
                                                        <MenuItem value="Political Science (Only Winter Semester) with 65% and above">Political Science (Only Winter Semester) with 65% and above</MenuItem>
                                                        <MenuItem value="Psychology (Only Winter Semester) with 80% and above">Psychology (Only Winter Semester) with 80% and above</MenuItem>
                                                        <MenuItem value="Sociology (Only Winter Semester) with 70% and above">Sociology (Only Winter Semester) with 70% and above</MenuItem>
                                                        <MenuItem value="Sports Science (Additional Assessment Test) (Only Winter Semester) with 70% and above">Sports Science (Additional Assessment Test) (Only Winter Semester) with 70% and above</MenuItem>
                                                        <MenuItem value="Health Economics &amp; Health Management (Only Winter Semester) with 65% and above">Health Economics &amp; Health Management (Only Winter Semester) with 65% and above</MenuItem>
                                                        <MenuItem value="2 Subjects Bachelor (Subject choice Computer Science, Chemistry, Mathematics, Physics)">2 Subjects Bachelor (Subject choice Computer Science, Chemistry, Mathematics, Physics)</MenuItem>
                                                        <MenuItem value="others">others</MenuItem>
                                                    </Select>

                                                    {this.state.preferred_course_error != '' &&

                                                        <FormHelperText>{this.state.preferred_course_error}</FormHelperText>
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item sm={12}>
                                                <FormControl fullWidth={true}>
                                                    <Button className="btn btn-red" type="submit" variant="contained">Submit</Button>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </form>
                                }
                                <Loader loading={this.state.loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        )
    }

    onFinish = (event) => {

        this.setState({error_message: ''});

        event.preventDefault();

        const formData = new FormData(event.target);

        if(!this.validate(formData)){
        
            var that = this;

            this.setState({loading: true});

            formData.append('country_id', this.state.country_id);
            formData.append('passing_year_10', this.state.passing_year_10_selected);
            formData.append('passing_year_12', this.state.passing_year_10_selected);

            formData.append('row_id', this.state.row_id);

            Api.post('eligibility', formData, function(data){
                
                that.setState({loading: false});

                if(data.status == true){
                
                    localStorage.removeItem('sf_student_token');
                    that.setState({redirect: true});
                
                }else{
                    
                    that.setState({error_message: data.message});
                }
            });
        }else{

            this.setState({error_message: 'There is error in your submission!'});
            this.form_container.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    validate = (formData) => {

        var _has_error = false;

        var email = formData.get('email');
        var mobile = formData.get('mobile');
        
        _has_error = this.isEmpty('first_name', _has_error, formData);
        _has_error = this.isEmpty('last_name', _has_error, formData);
        _has_error = this.isEmpty('percentage_12', _has_error, formData);
        _has_error = this.isEmpty('subjects_12', _has_error, formData);
        _has_error = this.isEmpty('additional_subjects_12', _has_error, formData);
        _has_error = this.isEmpty('percentage_10', _has_error, formData);
        // _has_error = this.isEmpty('passing_year_10', _has_error, formData);
        _has_error = this.isEmpty('preferred_course', _has_error, formData);

        if(email == '' || !ValidatorHelper.validEmail(email)){
            this.setState({email_error: 'Please enter valid email address'});
            _has_error = true;
        }else{
            this.setState({email_error: ''});
            _has_error = (!_has_error) ? false : true;
        }

        if(mobile == '' || mobile.length < 10 || mobile.length > 10 || isNaN(mobile)){
            this.setState({mobile_error: 'Please enter valid mobile number'});
            _has_error = true;
        }else{
            this.setState({mobile_error: ''});
            _has_error = (!_has_error) ? false : true;
        }

        var country_id = this.state.country_id;

        if(country_id == ''){
            this.setState({country_error: 'This is required field'});
            _has_error = true;
        }else{
            this.setState({country_error: ''});
            _has_error = (!_has_error) ? false : true;
        }

        var passing_year_12 = this.state.passing_year_12;
        
        if(passing_year_12 == '' || passing_year_12 === null){
            this.setState({passing_year_12_error: 'This is required field'});
            _has_error = true;
        }else{
            this.setState({passing_year_12_error: ''});
            _has_error = (!_has_error) ? false : true;
        }

        var passing_year_10 = this.state.passing_year_10;
        
        if(passing_year_10 == '' || passing_year_10 === null){
            this.setState({passing_year_10_error: 'This is required field'});
            _has_error = true;
        }else{
            this.setState({passing_year_10_error: ''});
            _has_error = (!_has_error) ? false : true;
        }

        if(this.state.states_list.length > 0){

            var state_id = formData.get('state_id');

            if(state_id == ''){
                this.setState({state_error: 'This is required field'});
                _has_error = true;
            }else{
                this.setState({state_error: ''});
                _has_error = (!_has_error) ? false : true;
            }
        }else{

            var state = formData.get('state');

            if(state == ''){
                this.setState({state_error: 'This is required field'});
                _has_error = true;
            }else{
                this.setState({state_error: ''});
                _has_error = (!_has_error) ? false : true;
            }
        }

        return _has_error;
    }

    isEmpty = (field, has_error, formData) => {

        var value = formData.get(field);

        if(value == ''){

            this.setState({[field + "_error"]: 'This is required field'});
            return true;
        }else{

            var _has_error = (!has_error) ? false : true;
        }

        return _has_error;
    }

    countriesList = () => {

        var that = this;

        const formData = new FormData();

        formData.append('country', true);
    
        Api.post('general/countries', formData, function(data){
                
            that.setState({
                countries: data.countries
            });
        });
    }

    statesList = (country_code) => {

        var that = this;

        this.setState({states_loading: true});

        const formData = new FormData();

        formData.append('country_code', country_code);

        Api.post('general/states', formData, function(data){
                
            that.setState({states_loading: false});

            if(data.status && data.states.length > 0){

                that.setState({states_list: data.states});
            }else{

                that.setState({states_list: []});
            }
        });
    }

    loadEnquery = (row_id) => {

        var that = this;

        const formData = new FormData();

        formData.append('id', row_id);

        Api.post('general/load_enquiry', formData, function(data){
                
            if(data.status){

                that.setState({enquiry: data.enquiry});

                that.setState({first_name: data.enquiry.first_name, last_name: data.enquiry.last_name, email: data.enquiry.email, mobile: data.enquiry.mobile_no});
            }
        });
    }
}

export default Signin;