import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Container, List, ListItem, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { Check, Info } from '@material-ui/icons';

import Bannerwithfullform from '../components/sections/Bannerwithfullform';

import Main from '../components/Main';

import studyplanningimage from '../assets/images/study-planning.png';

import gradcapimg from '../assets/images/graduation-cap.png';
import clockimg from '../assets/images/clock.png';
import languageimg from '../assets/images/language.png';

import clipboardimg from '../assets/images/clipboard.png';
import infoimg from '../assets/images/info-circle.png';

class TestAs extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    renderBannerContent = () => {
        
        return (
            <List component="nav" aria-label="main mailbox folders">
                <ListItem button>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    <ListItemText primary="No Studienkolleg Required" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    <ListItemText primary="Engineering and Medicine Available" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    <ListItemText primary="Only German Taught Programs" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    <ListItemText primary="Public Universities with Zero Tuition Fees" />
                </ListItem>
            </List>
        )
    }

    createData = (col1, col2, col3) => {
        return { col1, col2, col3 };
    }

    render () {

        const rows = [
            this.createData('First Batch', '1st July 2022', '4th July 2022'),
            this.createData('Second Batch', '25th July 2022', '1st August 2022'),
            this.createData('Third Batch', '25th August 2022', '1st September 2022'),
        ];

        return (

            <Main page="test_as_main" className="test-as main">
                <Bannerwithfullform page="enquiry" title="Guaranteed Admission!" subtitle="in Bachelors in Germany" content={this.renderBannerContent()} />

                <Container className="banner-below-container">
                    <div className="highlights-block">
                        <div className="text">
                            <span>100</span>
                            <span>Limited Seats only</span>
                        </div>
                        <div className="text">
                            <span>15th July*</span>
                            <span>Enrollment Last Date</span>
                        </div>
                    </div>
                    <div className="note">*Deadline for second batch.</div>
                </Container>

                <div className="section registration-schedule">
                    <Container>

                        <TableContainer component={Paper}>
                            <Table className="registration-schedule-table" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Registrations</TableCell>
                                        <TableCell align="center">Deadline</TableCell>
                                        <TableCell align="center">Class Commencing</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.col1}>
                                            <TableCell component="td" scope="row" align="center">
                                                {row.col1}
                                            </TableCell>
                                            <TableCell align="center">{row.col2}</TableCell>
                                            <TableCell align="center">{row.col3}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Container>
                </div>

                <div className="section study-planning-section">
                    <Container>
                        <div className="main-section d-flex">
                            <div className="text-block">
                                <div className="section-heading">Planning to study Bachelor’s in <span>Germany?</span></div>
                                <div className="text">Study Feeds is offering a guaranteed admissions program for bachelor’s in Germany for selective programs in Public Universities. <b>This is an exclusive program designed for school students looking for direct admissions in Germany in course of their choice after completing their 12th in India or abroad.</b> There are hundreds of choices in Germany for various streams but majority of the programs require an additional year of studies, but all the programs offered by us are German taught bachelor’s degree program in Germany which can be directly commenced from 1st semester after completing our preparatory course.</div>

                                <Link className="btn btn-red mt-2" to="/">Program Details</Link>
                            </div>
                            <div className="img-block">
                                <img className="img-fluid" src={studyplanningimage} alt="Image" />
                            </div>
                        </div>
                    </Container>
                </div>

                <div className="section why-germany">
                    <div className="section-bg">
                        {/* <div className="bg-block">
                            <img className="bg-image" src={whygermanybg} alt="Why Germany BG" />
                        </div> */}
                        <Container>
                            <div className="block">
                                <div className="section-heading">Why Germany</div>
                                <div className="section-subheading">The most lucrative destinations for Indian students</div>

                                <div className="main-section">
                                    <div className="bars">
                                        <div className="block">
                                            <div className="num">01</div>
                                            <div className="title">Zero or negligible</div>
                                            <div className="text">tuition fee in public universities</div>
                                            <div className="bar" style={{height:'47px'}}></div>
                                        </div>
                                        <div className="block">
                                            <div className="num">02</div>
                                            <div className="title">Low cost of living</div>
                                            <div className="text">for quality lifestyle</div>
                                            <div className="bar" style={{height:'65px'}}></div>
                                        </div>
                                        <div className="block">
                                            <div className="num">03</div>
                                            <div className="title">Gateway to all 26</div>
                                            <div className="text">Schengen countries</div>
                                            <div className="bar" style={{height:'88px'}}></div>
                                        </div>
                                        <div className="block">
                                            <div className="num">04</div>
                                            <div className="title">Economic powerhouse</div>
                                            <div className="text">of European Union</div>
                                            <div className="bar" style={{height:'111px'}}></div>
                                        </div>
                                        <div className="block">
                                            <div className="num">05</div>
                                            <div className="title">World class education</div>
                                            <div className="text">and amazing career ahead</div>
                                            <div className="bar" style={{height:'135px'}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    
                </div>

                <div className="section program-details">
                    
                    <Container>
                        <div className="block">
                            <div className="section-heading">Our Bachelor’s Program Details</div>
                            <div className="section-subheading">(Check the list given below for minimum % requirements for program of your choice.)</div>

                            <div className="main-section">
                                <div className="top-block">
                                    <div className="block">
                                        <img className="ico-img" src={gradcapimg} alt="Graduation Cap" />
                                        <div className="title">Program Name</div>
                                        <div className="details">Preparatory Course for Bachelor’s in Germany</div>
                                    </div>
                                    <div className="block">
                                        <img className="ico-img" src={clockimg} alt="Clock" />
                                        <div className="title">Duration</div>
                                        <div className="details">6 Months</div>
                                    </div>
                                    <div className="block">
                                        <img className="ico-img" src={languageimg} alt="Language" />
                                        <div className="title">Medium Of Study</div>
                                        <div className="details">German</div>
                                    </div>
                                    <div className="block">
                                        <img className="ico-img" src={clipboardimg} alt="Clipboard" />
                                        <div className="title">Test To Be Taken</div>
                                        <div className="details">TestAS (In German) and TestDaF <span>to prove German Language proficiency</span></div>
                                    </div>
                                </div>

                                <div className="mid-block">
                                    <div className="block">
                                        <div className="title">Procedure</div>
                                        <div className="steps">
                                            <div className="step"><b>STEP 1</b> Learn German from A1 – B1 level</div>
                                            <div className="step"><b>STEP 2</b> Simultaneously prepare for TestAS (during weekends) and learn language to prepare for TestDaF (during weekdays).</div>
                                        </div>
                                    </div>

                                    <div className="block">
                                        <div className="title">Eligibility</div>
                                        <div className="steps">
                                            <div className="step">1. Finished 12th Grade from any state recognized board ((including NIOS)</div>
                                            <div className="step">2. Students enrolled in 11th or 12th grade can do this program during weekends so they can directly start with their bachelors in the same year of clearing their 12th grade.</div>
                                            <div className="step">3. Students of 9th and 10th grade can start learning the language in order to directly start with their bachelors in the same year of clearing their 12th grade.</div>
                                            <div className="step">4. Students who already have a B1 certificate in German can directly begin with STEP 2.</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bottom-block">
                                    <div className="note-block"><img className="ico-img" src={infoimg} alt="Info" /> NOTE: We will also be helping the students with admission, visa and initial accomodations in Germany.</div>
                                    <Link className="btn btn-red" to="/">Enquire Now</Link>
                                </div>
                            </div>
                        </div>
                    </Container>
                    
                </div>

                <div className="section courses-offered">
                    <Container>
                        <div className="section-heading">Courses Offered</div>
                        <List className="courses-listing" component="nav" aria-label="main courses">
                            <ListItem>
                                <ListItemText primary="Mechanical Engineering (Only Winter Semester)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Business Administration" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Social Work (Only Winter Semester) with 70% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Automotive Engineering (Only Winter Semester)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Business Information Systems" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="German linguistics (Only Winter Semester) with 65% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Computer Science" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Philosophy (Only Winter Semester)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Information and Media Technology" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="English Linguistics (Only Winter Semester) with 65% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Human Medicine with 80% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Musicology (Only Winter Semester) (Additional assessment test)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Geography(Only Winter Semester) with 65% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Dentistry with 80% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Printing and Media Technology (Only Winter Semester)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Neurosciences with 70% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Political Science (Only Winter Semester) with 65% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Mathematics" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Industrial + Electrical Engineering (Only Winter Semester) (2 subject bachelor)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Psychology (Only Winter Semester) with 80% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Electrical Engineering" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Business Mathematics" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Interior Designing (Only Winter Semester) (Additional assessment test)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Sociology (Only Winter Semester) with 70% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Civil Engineering (Only Winter Semester)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Architecture (Only Winter Semester)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Transportation Engineering (Only Winter Semester)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Economics" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Sports Science (Additional Assessment Test) (Only Winter Semester) with 70% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Safety Engineering (Only Winter Semester)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Digital Medical Technology (Only Winter Semester) with 80% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Safety Engineering (Only Winter Semester)" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Physics" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Digital Public Health (Only Winter Semester) with 80% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Health Economics & Health Management (Only Winter Semester) with 65% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Chemistry" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Biomedical Technology (Only Winter Semester) with 80% and above" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="2 Subjects Bachelor (Subject choice Computer Science, Chemistry, Mathematics, Physics)" />
                            </ListItem>
                        </List>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default TestAs;