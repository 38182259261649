import React, { Component } from 'react';

import { Link, Redirect } from "react-router-dom";
import { Container, FormControl, FormControlLabel, TextField, Checkbox, Button } from '@material-ui/core';

import bannerimage from '../../assets/images/other-banner.png';

import { Alert, AlertTitle } from '@material-ui/lab';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

import ValidatorHelper from '../../helpers/ValidatorHelper';

function AgreeCheckbox() {
    const [checked, setChecked] = React.useState(true);
  
    const handleChange = (event) => {
      setChecked(event.target.checked);
    };
  
    return (
        <FormControlLabel control={<Checkbox checked={checked} name="agree" onChange={handleChange} />} label="I agree and affirm that Study Feeds may contact me and communicate with me over phone or email." />
    );
}

class Banners extends Component { 
    constructor(props) {
        super();
        this.state = {

            success_message: false,
            error_message: false,

            first_name_error: '',
            last_name_error: '',
            email_error: '',
            mobile_no_error: '',
            message_error: '',

            redirect: false
        }
    }


    render () {

        if(this.state.redirect != ''){

            return <Redirect to={"/thank-you/" + this.state.redirect} />
        }

        return (
            <div className="banner-slider">
                <div className="banners-block half-form">
                    <div className="bg-block">
                        <img className="banner-image" src={bannerimage} alt="Banner Image" />
                    </div>
                    <Container>
                        <div className="block">
                            {this.props.titleclasses && this.props.titleclasses != ''
                                ?
                                    <div className={this.props.titleclasses}>{this.props.title}</div>
                                :
                                   <div className="head-text">{this.props.title}</div>
                            }
                            
                            {this.props.subtitle && this.props.subtitle != ''
                                ?
                                    <div className="subhead-text">{this.props.subtitle}</div>
                                :
                                    null
                            }
                            <div className="description">{this.props.content}</div>
                        </div>
                        <div className="block">
                            <form className="form" name="enquiry_form" onSubmit={this.onFinish.bind(this)} autoComplete="off">
                                <div className="title">Admission in Germany</div>
                                <div className="subtitle">Enquiry</div>

                                {/* using if statement */}
                                {this.state.success_message &&
                                    <Alert severity="success" className="mb-30" onClose={() => {this.setState({success_message: false})}}>
                                        <AlertTitle>Success</AlertTitle>
                                        {this.state.success_message}
                                    </Alert>
                                    
                                }

                                {this.state.error_message &&
                                    <Alert severity="error" className="mb-30" onClose={() => {this.setState({error_message: false})}}>
                                        <AlertTitle>Error</AlertTitle>
                                        {this.state.error_message}
                                    </Alert>
                                }

                                <FormControl className="form-control">
                                    <TextField name="first_name" label="First Name" variant="outlined" error={this.state.first_name_error} helperText={this.state.first_name_error ? 'First Name is required' : ''} onChange={(e) => {

                                        if(e.target.value != ''){

                                            this.setState({first_name_error: ''})
                                        }
                                    }} />
                                </FormControl>

                                <FormControl className="form-control">
                                    <TextField name="last_name" label="Last Name" variant="outlined" error={this.state.last_name_error} helperText={this.state.last_name_error ? 'Last Name is required' : ''} onChange={(e) => {

                                        if(e.target.value != ''){

                                            this.setState({last_name_error: ''})
                                        }
                                    }} />
                                </FormControl>
                                    
                                <FormControl className="form-control">
                                    <TextField name="email" label="Email" variant="outlined" error={this.state.email_error} helperText={this.state.email_error ? 'Enter valid email' : ''} onChange={(e) => {

                                        if(e.target.value != '' && ValidatorHelper.validEmail(e.target.value)){

                                            this.setState({email_error: ''})
                                        }
                                    }} />
                                </FormControl>

                                <FormControl className="form-control">
                                    <TextField name="mobile_no" label="Mobile No." variant="outlined" error={this.state.mobile_no_error} helperText={this.state.mobile_no_error ? 'Enter valid mobile number' : ''} onChange={(e) => {

                                        if(e.target.value != '' && !isNaN(e.target.value) && e.target.value.length >= 10){

                                            this.setState({mobile_no_error: ''})
                                        }
                                    }} />
                                </FormControl>

                                <FormControl className="form-control">
                                    <TextField name="message" label="Message" variant="outlined" multiline rows={2} error={this.state.message_error} helperText={this.state.message_error ? 'Message is required' : ''} onChange={(e) => {

                                        if(e.target.value != ''){

                                            this.setState({message_error: ''})
                                        }
                                    }} />
                                </FormControl>

                                <FormControl className="form-control">
                                    <AgreeCheckbox />
                                </FormControl>
                                
                                <Button className="btn btn-red" type="submit" variant="contained">Enquire Now</Button>

                                <Loader loading={this.state.loading} />
                            </form>
                        </div>
                    </Container>
                </div>
            </div>
        )
    }

    onFinish = (event) => {

        event.preventDefault();

        const formData = new FormData(event.target);

        var _has_error = false;

        var first_name = formData.get('first_name');
        var last_name = formData.get('last_name');
        var email = formData.get('email');
        var mobile_no = formData.get('mobile_no');
        var message = formData.get('message');

        if(first_name == ''){
            this.setState({first_name_error: true});
            _has_error = true;
        }else{
            this.setState({first_name_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(last_name == ''){
            this.setState({last_name_error: true});
            _has_error = true;
        }else{
            this.setState({last_name_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(email == '' || !ValidatorHelper.validEmail(email)){
            this.setState({email_error: true});
            _has_error = true;
        }else{
            this.setState({email_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(mobile_no == '' || mobile_no.length < 10 || isNaN(mobile_no)){
            this.setState({mobile_no_error: true});
            _has_error = true;
        }else{
            this.setState({mobile_no_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(message == ''){
            this.setState({message_error: true});
            _has_error = true;
        }else{
            this.setState({message_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(!_has_error){

            this.setState({loading: true});

            if(this.props.page){

                formData.append('page_source', this.props.page);
            }
        
            var that = this;

            Api.post('enquiry', formData, function(data){
                
                that.setState({loading: false});

                if(data.status == true){
                
                    that.setState({loading: false, redirect: data.id});

                    that.setState({success_message: data.message});
                
                }else{
                    
                    that.setState({error_message: data.message});
                }
                window.setTimeout(() => {
                    that.setState({success_message: false});
                    that.setState({error_message: false});
                }, 10000);
            });
        }
    }
}

export default Banners;