import React, { Component } from 'react';

import { Button, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle } from '@material-ui/core';

import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardElement, ElementsConsumer} from '@stripe/react-stripe-js';

import Loader from '../../../components/Loader';

class CardForm extends Component {

    constructor(props) {
        super();
        this.state = {

            error: '',

            loading: false,

            cancel: false
        }
    }

    handleSubmit = async(event) => {

        event.preventDefault();

        const {stripe, elements} = this.props;

        var that = this;

        this.props.paymentProcessing(true);
        try{
        
            const result = await stripe.confirmCardPayment(this.props.secret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                }
            });

            if (result.error) {

                that.props.onError(result.error.message);
                that.props.paymentProcessing(false);
            } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                    
                    that.props.onSuccess(result);
                }
                that.props.paymentProcessing(false);
            }

        } catch (e) {

            that.props.onError("There was an error while processing your request! Please refresh the page and try again.");
        }
    }
    
    render() {
    
        const {stripe} = this.props;
        return (
          
            <form onSubmit={this.handleSubmit}>
                
                {this.props.secret &&
                    <div className="cc-form-container">
                        <div className="cc-form">
                            <CardElement />
                        </div>
                        <div className="cc-form-actions">
                            <Button size="small" color="primary" onClick={(e) =>  {

                                this.setState({cancel: e.currentTarget})
                            }} style={{marginRight:20}}>Cancel</Button>
                            <Button color="secondary" type="submit" variant="contained" size="small" disabled={!stripe}>Pay</Button>
                        </div>
                    </div>
                }

                <Dialog
                    open={this.state.cancel ? true : false}
                    onClose={() => {

                        this.setState({cancel: false})
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Cancel transaction"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Do you really want to cancel this transaction?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            
                            this.setState({cancel: false})
                        }} color="primary">
                            No
                        </Button>
                        <Button onClick={() => {

                            this.props.cancel()
                        }} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        );
    }
}

export default CardForm;