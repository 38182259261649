'use strict';

const Constants = {
    URL: {
        // root: 'http://localhost/study_feeds/',
        // api_url: 'http://localhost/study_feeds/req/',
        // server_url: 'http://localhost:3000/',

        root: 'https://testas.studyfeeds.com/',
        api_url: 'https://testas.studyfeeds.com/backend/req/',
        server_url: 'https://testas.studyfeeds.com/',
    },
    API_KEY: 'kfy74fdcf5s33'
}

module.exports = Constants;
