import React, { Component } from 'react';

// import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';

import { Alert, AlertTitle } from '@material-ui/lab';

class Main extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <React.Fragment>

                {this.props.mode && this.props.mode == 'full_screen'
                    ?
                        null
                    :
                        <AppHeader page_title={this.props.page_title} page={this.props.page} />
                }

                {this.props.error_message && this.props.error_message != ''
                    ?
                        <div className="header-alerts">
                            {/* <Alert description={this.props.error_message} type="error" showIcon /> */}
                            <Alert severity="error" onClose={() => {this.setState({error_message: false})}}>
                                <AlertTitle>Error</AlertTitle>
                                {this.props.error_message}
                            </Alert>
                        </div>
                    :
                        null
                }
                {this.props.success_message && this.props.success_message != ''
                    ?
                        <div className="header-alerts">
                            {/* <Alert description={this.props.success_message} type="success" showIcon /> */}
                            <Alert severity="success" onClose={() => {this.setState({success_message: false})}}>
                                <AlertTitle>Success</AlertTitle>
                                {this.props.success_message}
                            </Alert>
                        </div>   
                    :
                        null
                }

                <div className={(this.props.full_content ? 'main-content-full' : 'main-content') + ' ' + (this.props.className)}>

                    {(this.props.page_title && this.props.page_title != '') &&
                        <div className="main-content-title container">
                            <h1>{this.props.page_title}</h1>

                            {(this.props.page_subtitle && this.props.page_subtitle != '') &&
                                <strong className="page-subtitle">{this.props.page_subtitle}</strong>
                            }
                        </div>
                    }

                    {this.props.children}
                </div>

                {this.props.mode && this.props.mode == 'full_screen'
                    ?
                        null
                    :
                        <AppFooter />
                }
            </React.Fragment>
        )
    }
}

export default Main;