import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Container, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, LinearProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import AccountWrapper from './AccountWrapper';

import bannerimage from '../../assets/images/admin-banner.png';

import TestListingCard from './blocks/TestListingCard';
import PurchasedTestCard from './blocks/PurchasedTestCard';

import Api from '../../api/Api';

class Account extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            sf_student_token: false,

            loading_tests: false,
            tests: [],

            student_tests: [],

            purchased: false,

            show_result: false,

            success_message: '',
            error_message: ''
        }
    }

    componentDidMount = () => {

        // const script = document.createElement('script');
        // script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        // script.async = true;
        // document.body.appendChild(script);

        var sf_student_token = localStorage.getItem('sf_student_token');
        var user = localStorage.getItem('user');

        if(sf_student_token){

            user = JSON.parse(user);

            this.setState({sf_student_token: sf_student_token, user: user});
            this.loadTests(sf_student_token);

            var temp_error = localStorage.getItem('temp_error');

            if(temp_error){

                this.setState({error_message: temp_error});
                localStorage.removeItem('temp_error');

                window.setTimeout(() => {

                    this.setState({error_message: ''});
                }, 8000);
            }

        }else{

            window.location = Api.url + 'logout';
        }
    }

    render () {

        if(this.state.purchased){

            return <Redirect to="/account/tests" />
        }

        return (
            <AccountWrapper page_title="Account" success_message={this.state.success_message} error_message={this.state.error_message}>
                <div className="account dashboard">
                    
                    <div className="banner-block">
                        <img className="banner-img" src={bannerimage} />
                        <Container className="container">
                            <div className="txt">We Learn by <span className="yellow-txt">practice</span></div>
                        </Container>
                    </div>

                    <Container>

                        <div className="space-between">
                        
                            <div className="welcome-txt">Welcome, {this.state.user.name}</div>

                            {this.state.student_tests && this.state.student_tests.length > 0
                                ?

                                    <Link to="/account/tests" className="btn btn-success">Buy More Tests</Link>
                                :
                                    null
                            }
                        </div>
                    </Container>

                    <Container className="cards-listing">

                        {this.state.student_tests && this.state.student_tests.length > 0
                            ?
                                this.state.student_tests.map((_test, index) => {

                                    return (
                                        <PurchasedTestCard
                                            key={"purchased_test_" + index + '_' + _test.exam_id}    
                                            sf_student_token={this.state.sf_student_token}
                                            test={_test}
                                            successMessage={(message) => {
                                                this.setState({success_message: message})
                                            }}
                                            errorMessage={(message) => {
                                                this.setState({error_message: message})
                                            }}
                                            onSuccess={() => {

                                                window.setTimeout(() => {

                                                    this.setState({purchased: true})
                                                }, 2000);
                                            }}
                                            showResult={() => {

                                                this.setState({show_result: true})
                                            }}
                                        />
                                    )
                                })
                            :
                                null
                        }

                        {this.state.tests && this.state.tests.length > 0
                            ?
                                this.state.tests.map((_test) => {

                                    return (
                                        <TestListingCard
                                            key={"test_" + _test.id}    
                                            sf_student_token={this.state.sf_student_token}
                                            test={_test}
                                            user={this.state.user}
                                            successMessage={(message) => {
                                                this.setState({success_message: message})
                                            }}
                                            errorMessage={(message) => {
                                                this.setState({error_message: message})
                                            }}
                                            onSuccess={() => {

                                                this.setState({tests: [], student_tests: []});
                                                this.loadTests(this.state.sf_student_token);
                                                window.setTimeout(() => {
                                                    this.setState({success_message: ''});
                                                }, 5000)
                                            }}
                                            updateTests={(test) => {

                                                var tests = this.state.tests;

                                                for(var i in tests){

                                                    var single_test = tests[i];

                                                    if(single_test.id == test.id){

                                                        tests[i]['price'] = test['price'];
                                                        tests[i]['special_price'] = test['special_price'];
                                                        tests[i]['purchase_price'] = test['purchase_price'];
                                                    }
                                                }

                                                this.setState({tests: tests});
                                            }}
                                        />
                                    )
                                })
                            :
                                null
                        }

                        {this.state.loading_tests &&
                            this.renderSkeleton()
                        }
                        
                    </Container>

                    <Dialog className="modal" open={this.state.show_result} onClose={() => {
                        this.setState({show_result: false})
                    }} aria-labelledby="form-dialog-title">
                        
                        <DialogTitle id="form-dialog-title">
                            <h2 className="modal-title">
                                <span className="red-txt">TestAS Preparatory Test</span> | Economic Module
                                <span className="btn btn-blk btn-close" onClick={() => {
                                    this.setState({show_result: false})
                                }}>X</span>
                            </h2>
                        </DialogTitle>
                        <DialogContent>
                            <div className="description d-flex">
                                <div className="left-block">
                                    <div className="top">
                                        <div className="section first">
                                            <div className="heading">Subtest / Test Module</div>
                                        </div>

                                        <div className="section first">
                                            <div className="heading">Percentile Rank</div>
                                        </div>

                                        <div className="section first">
                                            <div className="heading">Standard Score</div>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="section first">
                                            <div className="details-listing">
                                                <div className="text">Solving Quantitative Problems (SQP)</div>
                                                <div className="text">Inferring Relationships (IR)</div>
                                                <div className="text">Completing Patterns (CP)</div>
                                                <div className="text">Continuing Numerical Series (CNS)</div>
                                                <div className="text">Core Test</div>
                                                <div className="text">Analysing Economic Interrelationships (AEI)</div>
                                                <div className="text">Analysing Processes (AP)</div>
                                                <div className="text">Economics Module (Economics)</div>
                                            </div>
                                        </div>

                                        <div className="section second">
                                            <div className="details-listing">
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                            </div>
                                        </div>

                                        <div className="section third">
                                            <div className="details-listing">
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="right-block">
                                    <div className="heading">Percentile Ranks</div>
                                    <div className="test-progress-listing">
                                        <div className="section">
                                            <div className="text">Core Test</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="84" />
                                                <span className="num">84</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">Economics</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="97" />
                                                <span className="num">97</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">SQP</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="86" />
                                                <span className="num">86</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">IR</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="79" />
                                                <span className="num">79</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">CP</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="58" />
                                                <span className="num">58</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">CNS</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="86" />
                                                <span className="num">86</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">AEI</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="84" />
                                                <span className="num">84</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">CNAPS</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="96" />
                                                <span className="num">96</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
                
            </AccountWrapper>
        )
    }

    renderSkeleton = () => {

        return (
            <div style={{width:'100%', minHeight:400, top:102, left:0, zIndex:999}}>
                <div>
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={230} style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={200} className="mt-10" style={{borderRadius:10}} />
                </div>
            </div>
        )
    }

    loadTests = (sf_student_token) => {

        const formData = new FormData();

        var that = this;

        this.setState({loading_tests: true});

        formData.append('sf_student_token', sf_student_token);

        Api.post('students/exams', formData, function(data){
            
            if(data.status){

                if(data.student_tests && data.student_tests.length){

                    that.setState({student_tests: data.student_tests, loading_tests: false});
                }else{

                    that.setState({tests: data.tests, loading_tests: false});
                }
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }
}

export default Account;