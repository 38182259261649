import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Card, CardHeader, CardContent, Button, ButtonGroup, TextField, Dialog, DialogContent, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import cardimage from '../../../assets/images/testAS.png';

import Loader from '../../../components/Loader';

import Api from '../../../api/Api';

import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardElement, ElementsConsumer} from '@stripe/react-stripe-js';

import InjectedCheckoutForm from './InjectedCheckoutForm';

const stripePromise = loadStripe('pk_live_51IY9lASBZ2gEyShGs8y53GhmFjeV6rsF6Ji6Ry40xjw9e4DCVg2nytJ9ue0TtGbAWhF3vPcrRykUHQN3UuhR1zTO00bVJdgLVj');
// const stripePromise = loadStripe('pk_test_51IY9lASBZ2gEyShGi4j1G9CkBU4quNjUrP3oz56FybB5qG0LFuKfNrhlOe1KRtdiYTfQR98RucL9LE6xIprgCqyi009O4E8OZB');

class TestListingCard extends Component { 
    constructor(props) {
        super();
        this.state = {

            qty: 1,
            modalOpen: false,

            order_number: '',

            loading: false,

            secret: '',

            student: false,
            purchased_exam: false,

            payment_loader: false,
            payment_data: [],

            screen_error: ''
        }
    }

    handleIncrement = () => {

        if(this.state.qty < 1){
        
            this.setState({qty: this.state.qty + 1});

            this.getPrice(this.props.test, (this.state.qty + 1));
        }
    }
    
    handleDecrement = () => {

        if(this.state.qty > 1){
        
            this.setState({qty: this.state.qty - 1});

            this.getPrice(this.props.test, (this.state.qty - 1));
        }
    }

    handleModalOpen = () => {
        this.setState({modalOpen: true});
    }
    
    handleModalClose = () => {
        this.setState({modalOpen: false});
    }

    getPrice = (exam, qty) => {

        const formData = new FormData();

        var that = this;

        this.setState({loading: true});

        formData.append('sf_student_token', this.props.sf_student_token);
        formData.append('exam_id', exam.row_id);
        formData.append('qty', qty);

        Api.post('exams/price_slab', formData, function(data){
            
            if(data.status){

                that.props.updateTests(data.exam);

            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.errorMessage(data.message);
                }
            }

            that.setState({loading: false});
        });
    }

    generateOrder = (exam) => {

        const formData = new FormData();

        var that = this;

        this.setState({loading: true});

        formData.append('sf_student_token', this.props.sf_student_token);
        formData.append('exam_id', exam.row_id);
        formData.append('qty', this.state.qty);

        Api.post('exams/order_intent', formData, function(data){
            
            that.setState({loading: false});

            if(data.status){

                that.setState({student: data.student, purchased_exam: data.exam});
                
                if(data.client_secret){

                    that.setState({secret: data.client_secret});
                }

            }else{

                that.setState({loading: false});

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.errorMessage(data.message);
                }
            }
        });
    }

    buyExam = () => {

        const formData = new FormData();

        var that = this;

        this.setState({loading: true});

        formData.append('sf_student_token', this.props.sf_student_token);
        formData.append('exam_id', this.state.purchased_exam.row_id);
        formData.append('qty', this.state.qty);
        
        formData.append('payment_data', JSON.stringify(this.state.payment_data));

        Api.post('exams/purchase', formData, function(data){
            
            if(data.status){

                that.setState({purchased_exam: false});
                that.props.successMessage(data.message);
                that.props.onSuccess();
            }else{

                that.setState({loading: false});

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.errorMessage(data.message);
                }
            }
        });
    }

    render () {

        if(this.props.test){
        
            return (

                <Elements stripe={stripePromise}>
            
                    <Card className="card">
                        <CardHeader title={this.props.test.title} />
                        <CardContent className="mb-20" style={{position:'relative'}}>

                            {this.state.secret != ''
                                ?
                                    <div className="payment-block">
                                
                                        {this.state.screen_error != '' &&

                                            <Alert severity="error">This is an error alert — check it out!</Alert>
                                        }

                                        {this.state.student && this.state.purchased_exam
                                            ?

                                                <>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell component="th">Name:</TableCell>
                                                                <TableCell>{this.state.student.name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th">Email:</TableCell>
                                                                <TableCell>{this.state.student.email}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th">Mobile:</TableCell>
                                                                <TableCell>{this.state.student.mobile_number}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th">Amount:</TableCell>
                                                                <TableCell>{this.state.purchased_exam.currency} {this.state.purchased_exam.final_amount}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>

                                                    <InjectedCheckoutForm
                                                        secret={this.state.secret}
                                                        cancel={() => {

                                                            this.setState({secret: '', purchased_exam: false})
                                                        }}
                                                        paymentProcessing={(status) => {

                                                            this.setState({payment_loader: status})
                                                        }}
                                                        onError={(message) => {

                                                            this.setState({screen_error: message})
                                                        }}
                                                        onSuccess={(data) => {

                                                            this.setState({payment_data: data, secret: ''});
                                                            this.buyExam();
                                                        }}
                                                    />
                                                </>
                                            :
                                                <Alert severity="error">Information missing! Please refresh the page and try again!</Alert>
                                        }
                                        
                                        {this.state.payment_loader &&
                                        
                                            <Loader loading={this.state.payment_loader} />
                                        }
                                    </div>
                                :

                                <div className="d-flex">
                                    {/* <div className="img-block">
                                        <img src={this.props.test.image} alt="Image" />
                                    </div> */}
                                    <div className="details-block">
                                        <div className="title">{this.props.test.title}</div>
                                        <div className="subtitle">{this.props.test.sub_title}</div>
                                        <div className="text">{this.props.test.description}</div>
                                    </div>
                                    <div className="inc-block">
                                        <div className="text">No. of Tests</div>
                                        <div>
                                            <Button onClick={this.handleDecrement}>-</Button>
                                            <TextField variant="outlined" onChange={(e) => {

                                                var qty = e.target.value;

                                                if(qty != ''){
                                                    
                                                    qty = parseInt(qty);

                                                    if(qty <= 0){

                                                        this.setState({qty: 1});
                                                        this.getPrice(this.props.test, 1);

                                                    }else if(!isNaN(qty)){

                                                        if(qty <= 1){
                                                        
                                                            this.setState({qty: qty});
                                                            this.getPrice(this.props.test, qty);
                                                        }
                                                    }
                                                }else{

                                                    this.setState({qty: 1});
                                                    this.getPrice(this.props.test, 1);
                                                }
                                            }} value={this.state.qty} />
                                            <Button onClick={this.handleIncrement}>+</Button>
                                        </div>
                                    </div>
                                    <div className="buttons-block">

                                        <div className="price-container">
                                            <div className="price-box">
                                                <div className={(this.props.test.hasOwnProperty('special_price') && this.props.test.special_price > 0) ? 'regular-price with-special' : 'regular-price'}>
                                                    <span className="price-currency">€</span>
                                                    <span className="price-amount">{this.props.test.price}</span>
                                                </div>

                                                {this.props.test.hasOwnProperty('special_price') && this.props.test.special_price > 0
                                                    ?
                                                        <div className="special-price">
                                                            <span className="price-amount">{this.props.test.special_price}</span>
                                                        </div>
                                                    :
                                                        null
                                                }
                                            </div>

                                            {this.props.test.hasOwnProperty('special_price') && this.props.test.special_price > 0
                                                ?
                                                    <>
                                                        <div className="price-discount">
                                                            <strong>{ ((parseInt(this.props.test.price) - parseInt(this.props.test.special_price)) * 100) / parseInt(this.props.test.price) }</strong>
                                                            <span>off</span>
                                                        </div>
                                                        <div className="price-saving">You save € {(parseInt(this.props.test.price) - parseInt(this.props.test.special_price))}</div>
                                                    </>
                                                :
                                                    null
                                            }
                                        </div>
                                        
                                        <Button onClick={() => {
                                            this.generateOrder(this.props.test)
                                        }} className="btn btn-success">Buy TestAS Test</Button>
                                    </div>
                                </div>
                            }

                            <Loader loading={this.state.loading} />
                        </CardContent>
                    </Card>
                </Elements>
            )
        }else{

            return <></>
        }
    }

    generateOrderDep = (exam) => {

        const formData = new FormData();

        var that = this;

        this.setState({loading: true});

        formData.append('sf_student_token', this.props.sf_student_token);
        formData.append('exam_id', exam.row_id);
        formData.append('qty', this.state.qty);

        Api.post('exams/generate_order', formData, function(data){
            
            if(data.status){

                that.setState({order_number: data.order_number});
                that.PayByRazorPay(data.exam);

            }else{

                that.setState({loading: false});

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.errorMessage(data.message);
                }
            }
        });
    }

    buyExamDep = (exam_id, razorpay_order_id, razorpay_payment_id, razorpay_signature) => {

        const formData = new FormData();

        var that = this;

        this.setState({loading: true});

        formData.append('sf_student_token', this.props.sf_student_token);
        formData.append('exam_id', exam_id);
        formData.append('qty', this.state.qty);
        
        formData.append('order_number', this.state.order_number);
        formData.append('razorpay_order_id', razorpay_order_id);
        formData.append('razorpay_payment_id', razorpay_payment_id);
        formData.append('razorpay_signature', razorpay_signature);

        Api.post('exams/purchase', formData, function(data){
            
            if(data.status){

                that.props.successMessage(data.message);
                that.props.onSuccess();
            }else{

                that.setState({loading: false});

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.errorMessage(data.message);
                }
            }
        });
    }

    PayByRazorPay = (exam) => {

        var that = this;

        that.setState({loading: false});

        if(this.state.exam){
            var _amount = this.state.exam.purchase_price;
        }else{
            var _amount = exam.purchase_price;
        }

        const options = {
            key: 'rzp_test_UWgW758C1GybTj',
            // key: 'rzp_live_ldYPLyn0bMddeC',
            // amount: exam.purchase_price,
            amount: _amount,
            name: 'Study Feeds',
            description: 'Purchase Test',
            image: Api.url + 'media/logo-square.png',
            currency: 'EUR',
            handler: function(response) {

                var razorpay_order_id = false;
                var razorpay_payment_id = false;
                var razorpay_signature = false;

                if(response.razorpay_order_id){

                    razorpay_order_id = response.razorpay_order_id;
                }
                if(response.razorpay_payment_id){

                    razorpay_payment_id = response.razorpay_payment_id;
                }
                if(response.razorpay_signature){

                    razorpay_signature = response.razorpay_signature;
                }

                that.buyExam(exam.row_id, razorpay_order_id, razorpay_payment_id, razorpay_signature);
            },
            prefill: {
                name: this.props.user.name,
                contact: this.props.user.mobile_number,
                email: this.props.user.email,
            },
            // notes: {
            //     address: 'some address'
            // },
            theme: {
                color: '#7303C1',
                hide_topbar: false
            }
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    }
}

export default TestListingCard;