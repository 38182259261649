import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, Paper, AppBar, Card, CardContent, CardMedia } from '@material-ui/core';

import Banners from '../pages/home/Banners';
import FaqsAccordion from '../pages/home/FaqsAccordion';

import Main from '../components/Main';

import bannerbelowimage1 from '../assets/images/exam.png';
import bannerbelowimage2 from '../assets/images/user.png';
import bannerbelowimage3 from '../assets/images/score.png';

import coretestimage from '../assets/images/core-test-img.png';
import yellowcertificate from '../assets/images/yellow-certificate.png';
import redcertificate from '../assets/images/red-certificate.png';

import whygermanyimage from '../assets/images/why-germany-img.png';

class Homepage extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <Main page="login" className="homepage">
                <Banners />

                <div className="banner-below-section">
                    <Card className="">
                        <CardContent>
                            <div className="image">
                                <img className="img-fluid" src={bannerbelowimage1} alt="Image" />
                            </div>
                            <div className="text">
                                <span>1600+</span>
                                <span>TestAS Questions</span>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className="">
                        <CardContent>
                            <div className="image">
                                <img className="img-fluid" src={bannerbelowimage2} alt="Image" />
                            </div>
                            <div className="text">
                                <span>1320+</span>
                                <span>Students</span>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className="">
                        <CardContent>
                            <div className="image">
                                <img className="img-fluid" src={bannerbelowimage3} alt="Image" />
                            </div>
                            <div className="text">
                                <span>84 Average</span>
                                <span>Test Score</span>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <div className="section core-test-section">
                    <Container>
                        <div className="head-text">TestAS is a central standardised aptitude test for foreign students.</div>
                        <div className="sub-text">TestAS gives prospective students information about their individual ranking compared to other applicants. With good results, they can improve their chances of being admitted for studies at a German university.</div>

                        <div className="main-section">
                            <div className="img-block">
                                <img className="img-fluid" src={coretestimage} alt="Image" />
                            </div>
                            <div className="mid-block"><i className="mat-ico">add</i></div>
                            <div className="test-blocks">
                                <div className="block yellow">
                                    <span className="top">Humanities, Cultural Studies, & Social Sciences Module</span>
                                    <span className="bottom">(150 min)</span>
                                </div>

                                <div className="block red">
                                    <span className="top">Engineering Module</span>
                                    <span className="bottom">(150 min)</span>
                                </div>

                                <div className="block red">
                                    <span className="top">Economic Module</span>
                                    <span className="bottom">(150 min)</span>
                                </div>

                                <div className="block yellow">
                                    <span className="top">Mathematics, Computer Science & Natural Science Module</span>
                                    <span className="bottom">(150 min)</span>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>

                <div className="section certification-section">
                    <Container>
                        <div className="section-heading">Certification</div>
                        <div className="section-subheading">According to the modules chosen, test takers will receive one of the 4 versions of the TestAS certificate. Samples can be viewed here.</div>

                        <div className="certifications">
                            <Card className="card red">
                                <CardContent>
                                    <CardMedia className="img-block" image={redcertificate} title="Contemplative Reptile" />
                                    <div className="text">Certificate for Core Test and Economics Module</div>
                                </CardContent>
                            </Card>
                            <Card className="card yellow">
                                <CardContent>
                                    <CardMedia className="img-block" image={yellowcertificate} title="Contemplative Reptile" />
                                    <div className="text">Certificate for Core Test and Engineering Module</div>
                                </CardContent>
                            </Card>
                            <Card className="card red">
                                <CardContent>
                                    <CardMedia className="img-block" image={redcertificate} title="Contemplative Reptile" />
                                    <div className="text">Certificate for Core Test and Humanities, Cultural Studies and....</div>
                                </CardContent>
                            </Card>
                            <Card className="card yellow">
                                <CardContent>
                                    <CardMedia className="img-block" image={yellowcertificate} title="Contemplative Reptile" />
                                    <div className="text">Certificate for Core Test and Mathematics, Computer Science and....</div>
                                </CardContent>
                            </Card>
                        </div>
                    </Container>
                </div>
                
                <div className="section faqs-section">
                    <Container>
                        <div className="section-heading">FAQs</div>
                        <FaqsAccordion />
                    </Container>
                </div>

                <div className="section why-germany-section">
                    <Container>
                        <div className="section-heading">Why Germany</div>
                        <div className="section-subheading">The most lucrative destinations for Indian students</div>

                        <div className="main-section">
                            <div className="img-block">
                                <img className="img-fluid" src={whygermanyimage} alt="Image" />
                            </div>
                            <div className="card-blocks">
                                <Card className="card">
                                    <CardContent>
                                        <div className="num">01</div>
                                        <div className="text">
                                            <div className="title">Zero or negligible</div>
                                            <div>tuition fee in public universities</div>
                                        </div>
                                    </CardContent>
                                </Card>

                                <Card className="card">
                                    <CardContent>
                                        <div className="num">02</div>
                                        <div className="text">
                                            <div className="title">Low cost of living</div>
                                            <div>for quality lifestyle</div>
                                        </div>
                                    </CardContent>
                                </Card>

                                <Card className="card">
                                    <CardContent>
                                        <div className="num">03</div>
                                        <div className="text">
                                            <div className="title">Gateway to all 26</div>
                                            <div>Schengen countries</div>
                                        </div>
                                    </CardContent>
                                </Card>

                                <Card className="card">
                                    <CardContent>
                                        <div className="num">04</div>
                                        <div className="text">
                                            <div className="title">Economic powerhouse</div>
                                            <div>of European Union</div>
                                        </div>
                                    </CardContent>
                                </Card>

                                <Card className="card">
                                    <CardContent>
                                        <div className="num">05</div>
                                        <div className="text">
                                            <div className="title">World class education</div>
                                            <div>and amazing career ahead</div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </Container>
                </div>

                {/* <div className="section testimonials-section">
                    <Container>
                        <div className="section-heading">What Our Students</div>

                        <div className="main-section">
                            <div className="testimonials">
                                <div className="single">
                                    <div className="text">Yesterday I received my TestAS-results (subject module engineering) and am very satisfied. Thank you very much for your books, which have helped me during the preparation a lot. I will definitely recommend your books to my friends.</div>
                                    <div className="name">Iryna</div>
                                    <div className="designation">TU9, Engineering</div>
                                </div>

                                <div className="single">
                                    <div className="text">I took the TestAS a month ago (22.02.2017) and used your books to prepare for the exam. I am very happy to say that I am very satisfied with the result. Core test 98% (122) and Subject Module MIN 96% (120). I wanted to thank you as I could never have achieved this score without you. Many many thanks and continue working like this! Kind regards</div>
                                    <div className="name">Natalia</div>
                                    <div className="designation">Applicant for medicine studies</div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div> */}
            </Main>
        )
    }
}

export default Homepage;