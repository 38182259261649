import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Container } from '@material-ui/core';

import Main from '../../components/Main';

import bannerimage from '../../assets/images/other-banner.png';

import ReactPixel from 'react-facebook-pixel';

class EnquirySuccess extends Component { 
    constructor(props) {
        super();
        this.state = {

            row_id: false
        }
    }

    componentDidMount = () => {

        var row_id = this.props.row_id;

        if(row_id){

            this.setState({row_id: this.props.row_id});

            localStorage.setItem('enquiry_id', this.props.row_id);
        }

        ReactPixel.init('867020647165618');
        ReactPixel.pageView();
        ReactPixel.track('track', 'Lead');
    }

    render () {

        return (

            <Main page="test_as_main" className="contact-us">

                <div className="banner-slider">
                    <div className="banners-block half-form">
                        <div className="bg-block">
                            <img className="banner-image" src={bannerimage} alt="Banner Image" />
                        </div>
                        <Container>
                            <div className="center-align-page">

                                <div className="align-center">

                                    <div className="block-wrapper sm">

                                        <div className="thanks-message">

                                            <h2>Thank you!</h2>
                                            <p>for expressing your interest in our </p>
                                            <p><strong>TestAS Preparatory Program.</strong></p>

                                            <hr />

                                            <h3>The admissions for this course are currently open.</h3>
                                            <p>The first step in the process is to fill the online eligibility form for the TestAS examination.</p>

                                            <Link to={"/eligibility-form/" + this.state.row_id} className="link-btn mt-10">Eligibility form</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </Main>
        )
    }
}

export default EnquirySuccess;