import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import Bannerwithform from '../components/sections/Bannerwithform';

import Main from '../components/Main';

class SubjectChoices extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <Main page="test_as_main" className="subject-choices">
                <Bannerwithform page="enquiry" title="Subject Choices" />

                <div className="section subject-choices-section">
                    <Container>
                        <div className="main-section">
                            <p>Most importantly, the previous subject choice of the student is also relevant while applying for the bachelor’s program in Germany. For example a student having PCM (even with Economics) cannot study bachelor’s program related to management (including Economics) in Germany. Simply clearing the TestAS in the relevant module won’t suffice. Kindly study the brief below about the relevant study programs on the basis of stream pursued in 11th and 12th:</p>

                            <List className="options-listing" component="nav" aria-label="main options">
                                <ListItem>
                                    <ListItemIcon>1.</ListItemIcon>
                                    <ListItemText primary="PCM – Engineering, Business and Engineering, Architecture, Physics, Chemistry, Biotechnology, Business Informatics, Sports Engineering, Food Technology, Print and Media Technology, etc." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>2.</ListItemIcon>
                                    <ListItemText primary="PCB – Biology, Pharmacy, Veterinary Medicine, Biochemistry, Nutrition, Sports Sciences, Health Sciences, Zoology, Botany, Plant Sciences, Psychology, Biotechnology, etc." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>3.</ListItemIcon>
                                    <ListItemText primary="Commerce – Economics, Business Management, Business Administration, Finance, Accounting, Business Informatics, Business and Engineering, International Business, Sports Management, etc." />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>4.</ListItemIcon>
                                    <ListItemText primary="Humanities – Geography, Linguistics, Psychology, Geography, History, Journalism, Musicology, Philosophy, Political Science, Sociology, International Relations, etc." />
                                </ListItem>
                            </List>

                            <div className="note">NOTE: A student who has studied PCMB during his 11th and 12th grade he/she can also study all the programs offered for PCM and PCB students.</div>

                        </div>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default SubjectChoices;