import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Container } from '@material-ui/core';

import Main from '../../components/Main';

import bannerimage from '../../assets/images/other-banner.png';

import ReactPixel from 'react-facebook-pixel';

class EligibilitySuccess extends Component { 
    constructor(props) {
        super();
        this.state = {

            row_id: false
        }
    }

    componentDidMount = () => {

        var row_id = this.props.row_id;

        if(row_id){

            this.setState({row_id: this.props.row_id});

            localStorage.setItem('enquiry_id', this.props.row_id);
        }

        ReactPixel.init('867020647165618');
        ReactPixel.pageView();
        ReactPixel.track('track', 'Lead');
    }

    render () {

        return (

            <Main page="test_as_main" className="contact-us">

                <div className="banner-slider">
                    <div className="banners-block half-form">
                        <div className="bg-block">
                            <img className="banner-image" src={bannerimage} alt="Banner Image" />
                        </div>
                        <Container>
                            <div className="center-align-page">

                                <div className="align-center">

                                    <div className="block-wrapper sm">

                                        <div className="thanks-message">

                                            <h2 className="done">Thank you!</h2>
                                            <p>Thank you for filling up the eligibility form.</p>
                                            <p>We are in the process of evaluating your application for our TestAS Preparatory Program. We request you to have some patience and we shall get back to you with the result of our evaluation within the next two days.</p>

                                            <p>Keep checking your inbox for the details.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </Main>
        )
    }
}

export default EligibilitySuccess;