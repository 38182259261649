import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import Bannerwithform from '../components/sections/Bannerwithform';

import Main from '../components/Main';

import coretestimage from '../assets/images/core-test-img.png';

class AboutTestAs extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    createData = (type1, type2, type3, type4) => {
        return { type1, type2, type3, type4 };
    }

    render () {

        const rows = [
            this.createData('German language and literature', 'Mechanical Engineering', 'Mathematics', 'Business Economics'),
            this.createData('Linguistics', 'Electrical Engineering', 'Chemistry', 'Political Economics'),
            this.createData('Sociology', 'Mechatronics Engineering', 'Physics', 'Management'),
            this.createData('Politics', 'Civil', 'Computer Science', 'Business Management'),
            this.createData('Philosophy', 'Automotive Engineering', 'Psychology', 'Education Management'),
            this.createData('Historical Science', 'Environmental Engineering', 'Pharmacology', 'Energy and Water Management'),
            this.createData('Law', 'Chemical Engineering', 'Medicine', ''),
        ];

        return (

            <Main page="test_as_main" className="test-as about">
                <Bannerwithform page="enquiry" title="About TestAS" />

                <div className="section core-test-section">
                    <Container>
                        <div className="sub-text">German universities have recently started accepting TestAS as an alternative to directly start bachelor’s in selected programs in Germany. The student will be tested across two modules. Every student will mandatorily take the Core Test and the specialized test will be taken on the basis of the course the student is willing to pursue in Germany. TestAS is offered in both English as well as German. With us the students will be preparing to take this exam in German as it is widely acceptable by the German universities.</div>

                        <div className="sub-text">There are 4 modules in this test depending on the desired study program, namely:</div>

                        <div className="main-section">
                            <div className="img-block">
                                <img className="img-fluid" src={coretestimage} alt="Image" />
                            </div>
                            <div className="mid-block"><i className="mat-ico">add</i></div>
                            <div className="test-blocks">
                                <div className="block yellow">
                                    <span className="top">Humanities, Cultural Studies, & Social Sciences Module</span>
                                    <span className="bottom">(150 min)</span>
                                </div>

                                <div className="block red">
                                    <span className="top">Engineering Module</span>
                                    <span className="bottom">(150 min)</span>
                                </div>

                                <div className="block red">
                                    <span className="top">Economic Module</span>
                                    <span className="bottom">(150 min)</span>
                                </div>

                                <div className="block yellow">
                                    <span className="top">Mathematics, Computer Science & Natural Science Module</span>
                                    <span className="bottom">(150 min)</span>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>

                <div className="section test-modules">
                    <Container>
                        <div className="section-heading">Which specific test module should I choose?</div>
                        <div className="section-subheading">The university you have decided to study at will determine which Subject-Specific Module you will be required to take according to your chosen course of studies.</div>
                        <div className="section-subheading">Below you will find some examples of which courses may well suit which Subject-Specific Module.</div>

                        <TableContainer component={Paper}>
                            <Table className="test-modules-table" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Humanities, Cultural Studies and Social Sciences</TableCell>
                                        <TableCell>Engineering</TableCell>
                                        <TableCell>Mathematics, Computer Science and Natural Sciences</TableCell>
                                        <TableCell>Economics</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.type1}>
                                            <TableCell component="th" scope="row">
                                                {row.type1}
                                            </TableCell>
                                            <TableCell>{row.type2}</TableCell>
                                            <TableCell>{row.type3}</TableCell>
                                            <TableCell>{row.type4}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className="ref mt-2 text-right">Reference: www.testas.de</div>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default AboutTestAs;