import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import Bannerwithform from '../components/sections/Bannerwithform';

import Main from '../components/Main';

class PreviousProcedure extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <Main page="test_as_main" className="previous-procedure">
                <Bannerwithform title="Previous Procedure" />

                <div className="section requirements-section">
                    <Container>
                        <div className="main-section">
                            <p>There are two requirements to be fulfilled in order to start the bachelor’s program in Germany :</p>

                            <List className="options-listing" component="nav" aria-label="main options">
                                <ListItem>
                                    <ListItemIcon>1.</ListItemIcon>
                                    <ListItemText><b>Language</b></ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>2.</ListItemIcon>
                                    <ListItemText><b>Academic</b></ListItemText>
                                </ListItem>
                            </List>

                        </div>
                    </Container>
                </div>

                <div className="section">
                    <Container>
                        <div className="section-heading">Language :</div>
                        <div className="main-section">
                            <p>The student needs <b>one</b> of the following certificates in German language (Like we have IELTS and TOEFL to prove English language proficiency):</p>

                            <List className="options-listing" component="nav" aria-label="main options">
                                <ListItem>
                                    <ListItemIcon>1.</ListItemIcon>
                                    <ListItemText>TestDaF (Grade – TDN 4) – Can be taken in India</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>2.</ListItemIcon>
                                    <ListItemText>Goethe C2 (Just Pass) – Can be taken in India</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>3.</ListItemIcon>
                                    <ListItemText>DSH-2 (Only conducted by German universities in Germany)</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>4.</ListItemIcon>
                                    <ListItemText>TELC C1 Hochschule – Conducted only in Germany</ListItemText>
                                </ListItem>
                            </List>

                            <div className="note"><b>Note:</b> There are few more certificates but these 4 are the most recognized ones which are accepted by all the universities across Germany.</div>

                        </div>
                    </Container>
                </div>

                <div className="section">
                    <Container>
                        <div className="section-heading">Academic :</div>
                        <div className="main-section">
                            <p>Previously, there were only 3 ways to start bachelor’s degree in Germany, namely:</p>

                            <List className="options-listing" component="nav" aria-label="main options">
                                <ListItem>
                                    <ListItemIcon>1.</ListItemIcon>
                                    <ListItemText>Either complete the first year of a relevant degree program in India. For example, if a student wants to study B. Sc. Mechanical Engineering in Germany, he needs to do his first year (2 semesters) at a UGC recognized university from India in Mechanical Engineering.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>2.</ListItemIcon>
                                    <ListItemText>Do one year of preparatory program called Studienkolleg in Germany.  Seats are very limited in Public Studienkollegs and success rate for getting into them ranges between 5-10% at max. Private Studienkollegs charges hefty tuition fees ranging from 9000 to 20000 Euros (Approx 8 Lakhs to 17 Lakhs INR) for one year program.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>3.</ListItemIcon>
                                    <ListItemText>Students clearing IIT-JEE ADVANCE can directly start their bachelor’s degree in Germany. </ListItemText>
                                </ListItem>
                            </List>

                        </div>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default PreviousProcedure;