import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, List, ListItem, ListItemText } from '@material-ui/core';

import Bannerwithform from '../components/sections/Bannerwithform';

import Main from '../components/Main';

import aboutstudyfeedsimage from '../assets/images/about-studyfeeds.png';

class AboutStudyFeeds extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <Main page="test_as_main" className="about-studyfeeds">
                <Bannerwithform title="About Studyfeeds" page="enquiry" />

                <div className="section studyfeeds-section">
                    <Container>

                        <div className="main-section d-flex">
                            <div className="text-block">
                                <div className="section-heading">Counselors with experience of more than 10 years in consulting students for higher education in Germany.</div>

                                <List className="options-listing bullets" component="nav" aria-label="main options">
                                    <ListItem>
                                        <ListItemText>Centrally located in Connaught Place, New Delhi since 2014. </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>Majority of our clientele is through word-of-mouth.</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText><b>Specialized Consultancy.</b> Tending exclusively to students interested in Public Universities (for free education) only in <b>Germany.</b></ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>Majority of our clientele is through word-of-mouth.</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>Majority of our clientele is through word-of-mouth.</ListItemText>
                                    </ListItem>
                                </List>
                            </div>

                            <div className="img-block">
                                <img className="img-fluid" src={aboutstudyfeedsimage} alt="Image" />
                            </div>
                        </div>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default AboutStudyFeeds;