import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Container, FormControl, InputLabel, FormHelperText, TextField, Select, Radio, RadioGroup, MenuItem, FormGroup, Button, FormControlLabel } from '@material-ui/core';

import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

import Main from '../../components/Main';

import ValidatorHelper from '../../helpers/ValidatorHelper';

import AuthSidebar from './AuthSidebar';

class Signup extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,
            login: false,
            redirect: false,
            
            success_message: false,
            error_message: false,

            countries: [],
            states_list: [],
            categories: [],

            states_loading: false,

            country_id: '',
            selected_state: '',
            selected_language: '',

            name_error: false,
            email_error: false,
            password_error: false,
            confirm_password_error: false,
            city_error: false,
            address_error: false,
            mobile_number_error: false,
            country_error: false,
            state_error: false,
            category_error: false,
            language_error: false,
            zipcode_error: false
        }

        this.form_container = React.createRef();
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('sf_student_token');
        
        if(account_token){
            this.setState({login: true});
        }

        this.countriesList();
    }

    render () {
        if(this.state.login){
            return <Redirect to='/account/profile-update'/>;
        }

        if(this.state.redirect){
            return <Redirect to='/signin'/>;
        }

        return (

            <Main page="signuppage" className="signuppage">
                <div page="signup" className="signup-main-page">
                    <div className="container">
                        <div className="signup-page d-flex items-end">
                            
                            <AuthSidebar />
                            <div className="right half-container self-right auth-block" style={{position:'relative'}}>

                                <div className="section-heading" ref={this.form_container}>Sign Up</div>

                                {this.state.success_message &&
                                    <Alert severity="success" className="mb-30">
                                        <AlertTitle>Success</AlertTitle>
                                        {this.state.success_message}
                                    </Alert>
                                }

                                {this.state.error_message &&
                                    <Alert severity="error" className="mb-30" onClose={() => {this.setState({error_message: false})}}>
                                        <AlertTitle>Error</AlertTitle>
                                        {this.state.error_message}
                                    </Alert>
                                }
                                
                                {!this.state.success_message &&

                                    <>
                                        <form className="form" name="signup_form" id="signup_form" onSubmit={this.onSubmit.bind(this)}>
                                            
                                            <FormGroup className="d-flex">
                                                <FormControl className="form-control">
                                                    <TextField name="name" id="name" label="Full Name" variant="outlined" autoComplete="off" error={this.state.name_error} helperText={this.state.name_error ? 'Name is required' : ''} onBlur={() => {
                                                        this.singleFieldValidator('name')
                                                    }} />
                                                </FormControl>
                                                <FormControl className="form-control">
                                                    <TextField name="mobile_number" id="mobile_number" label="Mobile No." variant="outlined" autoComplete="off" error={this.state.mobile_number_error} helperText={this.state.mobile_number_error ? 'Enter valid mobile number' : ''} onBlur={() => {
                                                        this.singleFieldValidator('mobile_number', 'number')
                                                    }} />
                                                </FormControl>
                                            </FormGroup>

                                            <FormControl className="form-control">
                                                <TextField name="email" id="email" label="Email" variant="outlined" autoComplete="off" error={this.state.email_error} helperText={this.state.email_error ? 'Enter valid email address' : ''} onBlur={() => {
                                                    this.singleFieldValidator('email', 'email')
                                                }} />
                                            </FormControl>

                                            <FormControl className="form-control">
                                                <TextField name="password" id="password" label="Password" variant="outlined" type="password" autoComplete="off"error={this.state.password_error} helperText={this.state.password_error ? 'Enter valid password' : 'Minimum 6 digits'} type="password" onBlur={() => {
                                                    this.singleFieldValidator('password', 'password')
                                                }} />
                                            </FormControl>

                                            <FormControl className="form-control">
                                                <TextField name="confirm_password" id="confirm_password" label="Confirm Password" variant="outlined" type="password" autoComplete="off"error={this.state.confirm_password_error} helperText={this.state.confirm_password_error ? 'Password did not match' : 'Minimum 6 digits'} onBlur={() => {
                                                    this.singleFieldValidator('confirm_password', 'confirm_password')
                                                }} />
                                            </FormControl>

                                            <FormControl className="form-control">
                                                <TextField name="address" id="address" label="Address" variant="outlined" autoComplete="off" error={this.state.address_error} helperText={this.state.address_error ? 'Address is required' : ''} onBlur={() => {
                                                    this.singleFieldValidator('address')
                                                }} />
                                            </FormControl>

                                            <FormControl className="form-control">
                                                <TextField name="city" id="city" label="City" variant="outlined" autoComplete="off" error={this.state.city_error} helperText={this.state.city_error ? 'City is required' : ''} onBlur={() => {
                                                    this.singleFieldValidator('city')
                                                }} />
                                            </FormControl>
                                            
                                            <FormGroup className="d-flex">
                                                
                                                <FormControl className="form-control select">

                                                    <FormControl fullWidth={true} component="fieldset" error={this.state.zipcode_error}>
                                                        <TextField
                                                            name="zipcode"
                                                            id="zipcode"
                                                            label="Zipcode"
                                                            variant="outlined"
                                                            autoComplete="off"
                                                            error={this.state.zipcode_error}
                                                            helperText={this.state.zipcode_error ? 'Zipcode is required' : ''}
                                                            onBlur={() => {
                                                            this.singleFieldValidator('zipcode')
                                                        }} />
                                                    </FormControl>
                                                    
                                                </FormControl>

                                            </FormGroup>

                                            <FormGroup className="d-flex">
                                                
                                                <FormControl className="form-control select">

                                                    <FormControl fullWidth={true} component="fieldset" error={this.state.country_error}>
                                                        <Autocomplete
                                                            margin="normal"
                                                            options={this.state.countries}
                                                            getOptionLabel={(option) => {
                                                                return option.name
                                                            }}
                                                            autoComplete="off"
                                                            disableClearable
                                                            renderInput={(params) => <TextField variant="outlined" {...params} error={this.state.country_error} label="Country *" />}
                                                            onChange={(option, value) => {
                                                                this.setState({country_id: value.iso});
                                                                this.statesList(value.iso);
                                                            }}
                                                        />
                                                        <FormHelperText>{this.state.country_error ? 'Please choose country' : ''}</FormHelperText>
                                                    </FormControl>
                                                    
                                                </FormControl>

                                                <FormControl className={"form-control " + (this.state.states_list.length > 0 ? 'select' : '')}>
                                                
                                                    {this.state.states_list.length == 0
                                                        ?
                                                            
                                                            <TextField name="state" id="state" label="State" variant="outlined" autoComplete="off" error={this.state.state_error} helperText={this.state.state_error ? 'State is required' : ''} onBlur={() => {
                                                                this.singleFieldValidator('state')
                                                            }} />
                                                        :
                                                            <>
                                                                <InputLabel htmlFor="state_id">State</InputLabel>
                                                                <Select name="state_id" label="State" variant="outlined" error={this.state.state_error} helperText={this.state.state_error ? 'State is required' : ''} onChange={() => {
                                                                    this.setState({state_error: false})
                                                                }}>

                                                                    {this.state.states_list.length > 0 &&

                                                                        this.state.states_list.map((_state) => {

                                                                            return <MenuItem key={"state_" + _state.id} value={_state.id}>{_state.name}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </>
                                                    }

                                                    <Loader loading={this.state.states_loading} />
                                                </FormControl>
                                            </FormGroup>

                                            <FormControl className="form-control select">
                                                
                                                <InputLabel>Select Interested Category</InputLabel>
                                                <Select name="category" label="Select Interested Category" variant="outlined" error={this.state.category_error} helperText={this.state.category_error ? 'Category is required' : ''} onChange={() => {
                                                    this.setState({category_error: false})
                                                }}>
                                                    <MenuItem value=""><em>Category</em></MenuItem>
                                                    
                                                    {this.state.categories.length > 0 &&

                                                        this.state.categories.map((_category) => {

                                                            if(_category.title != 'Core' && _category.title != 'core'){
                                                                return <MenuItem key={"category_" + _category.id} value={_category.id}>{_category.title}</MenuItem>
                                                            }
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>

                                            <FormGroup className="d-flex radio-buttons">
                                                <div>Preferred Language:</div>
                                                <FormControl className="form-control" error={this.state.language_error}>

                                                    <RadioGroup className="d-flex" name="language">
                                                        <FormControlLabel value="english" control={<Radio color="primary" />} label="English" />
                                                        <FormControlLabel value="german" control={<Radio color="primary" />} label="German" />
                                                    </RadioGroup>

                                                    {this.state.language_error &&
                                                        <FormHelperText>Please choose preferred language</FormHelperText>
                                                    }
                                                </FormControl>
                                            </FormGroup>

                                            <Button className="btn btn-red" type="submit" variant="contained">Signup</Button>
                                            
                                        </form>
                                      
                                        <div className="text-center signup-link">
                                            Already have an account? <Link to="/signin" className="red-txt">Login</Link>
                                        </div>
                                    </>
                                }

                                <Loader loading={this.state.loading} />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        )
    }

    onSubmit = (event) => {

        this.setState({error_message: false, success_message: false});

        event.preventDefault();

        if(this.state.loading){

            return false;
        }

        if(!this.validateForm()){

            const formData = new FormData(event.target);

            formData.append('country', this.state.country_id);

            var that = this;
            this.setState({loading: true});

            Api.post('auth/signup', formData, function(data){
                
                if(data.status == true){
                    
                    that.setState({loading: false});

                    that.setState({success_message: data.message});

                    that.form_container.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

                    window.setTimeout(() => {
                        that.setState({redirect: true});
                    }, 10000);
                
                }else{

                    that.setState({loading: false});

                    if(data.code && data.code == 'information_missing'){

                        var missing_fields = data.errors;
                        var message = data.message + ' - ' + missing_fields.join(', ');
                        that.setState({error_message: message});
                    }else{

                        that.setState({error_message: data.message});
                    }

                    that.form_container.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            });
        }else{

            this.setState({error_message: 'There is error in your submission!'});
            this.form_container.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    validateForm = () => {

        this.setState({error_message: ''});

        var form = document.getElementById('signup_form');

        const data = new FormData(form);

        var _has_error = false;

        var email = data.get('email');
        var password = data.get('password');
        var confirm_password = data.get('confirm_password');
        var name = data.get('name');
        var address = data.get('address');
        var mobile_number = data.get('mobile_number');
        var city = data.get('city');
        var country = data.get('country');
        var zipcode = data.get('zipcode');

        console.log(country);
        
        var category = data.get('category');
        var language = data.get('language');

        if(this.state.states_list.length > 0){

            var state_id = data.get('state_id');

            if(state_id == ''){
                this.setState({state_error: true});
                _has_error = true;
            }else{
                this.setState({state_error: false});
                _has_error = (!_has_error) ? false : true;
            }
        }else{

            var state = data.get('state');

            if(state == ''){
                this.setState({state_error: true});
                _has_error = true;
            }else{
                this.setState({state_error: false});
                _has_error = (!_has_error) ? false : true;
            }
        }
        
        if(zipcode == ''){
            this.setState({zipcode_error: true});
            _has_error = true;
        }else{
            this.setState({zipcode_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(city == ''){
            this.setState({city_error: true});
            _has_error = true;
        }else{
            this.setState({city_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(language == null || language == ''){

            this.setState({language_error: true});
            _has_error = true;
        }else{
            this.setState({language_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(category == ''){
            this.setState({category_error: true});
            _has_error = true;
        }else{
            this.setState({category_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(this.state.country_id == ''){
            this.setState({country_error: true});
            _has_error = true;
        }else{
            this.setState({country_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(email == '' || !ValidatorHelper.validEmail(email)){
            this.setState({email_error: true});
            _has_error = true;
        }else{
            this.setState({email_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(password == '' || password.length < 6){
            this.setState({password_error: true});
            _has_error = true;
        }else{
            this.setState({password_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(password == '' || confirm_password == '' || confirm_password.length < 6 || password != confirm_password){
            this.setState({confirm_password_error: true});
            _has_error = true;
        }else{
            this.setState({confirm_password_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(name == ''){
            this.setState({name_error: true});
            _has_error = true;
        }else{
            this.setState({name_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(address == ''){
            this.setState({address_error: true});
            _has_error = true;
        }else{
            this.setState({address_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(mobile_number == '' || mobile_number.length < 10 || mobile_number.length > 10){
            this.setState({mobile_number_error: true});
            _has_error = true;
        }else{
            this.setState({mobile_number_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        return _has_error;
    }

    countriesList = () => {

        var that = this;

        const formData = new FormData();

        formData.append('country', true);
    
        Api.post('general/grouped', formData, function(data){
                
            that.setState({
                countries: data.countries,
                categories: data.categories
            });
        });
    }

    statesList = (country_code) => {

        var that = this;

        this.setState({states_loading: true});

        const formData = new FormData();

        formData.append('country_code', country_code);
    
        Api.post('general/states', formData, function(data){
                
            that.setState({states_loading: false});

            if(data.status && data.states.length > 0){

                that.setState({states_list: data.states});
            }else{

                that.setState({states_list: []});
            }
        });
    }

    singleFieldValidator = (field, field_type) => {

        var form = document.getElementById('signup_form');
        
        const data = new FormData(form);

        var _has_error = false;

        var _field = data.get(field);

        var field_state = field + '_error';

        if(_field == ''){

            this.setState({[field_state]: true});
            _has_error = true;
        }else{

            if(field_type == 'number'){
                if((isNaN(_field)) || _field <= 0){
                    this.setState({[field_state]: true});
                    _has_error = true;
                }else{
                    this.setState({[field_state]: false});
                    _has_error = (!_has_error) ? false : true;
                }
            }else if(field_type == 'email'){
                if(!ValidatorHelper.validEmail(_field)){
                    this.setState({[field_state]: true});
                    _has_error = true;
                }else{
                    this.setState({[field_state]: false});
                    _has_error = (!_has_error) ? false : true;
                }
            }else if(field_type == 'password'){
                if(_field.length < 6){
                    this.setState({[field_state]: true});
                    _has_error = true;
                }else{
                    this.setState({[field_state]: false});
                    _has_error = (!_has_error) ? false : true;
                }
            }else{
                this.setState({[field_state]: false});
                _has_error = (!_has_error) ? false : true;
            }
        }

        return _has_error;
    }
}

export default Signup;