import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, List, ListItem, ListItemText } from '@material-ui/core';

import Bannerwithfullform from '../../components/sections/Bannerwithfullform';

import Main from '../../components/Main';


class Contact extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <Main page="test_as_main" className="contact-us">
                <Bannerwithfullform title={<><span>Contact Us</span><br /><span>To Find Out More</span></>} titleclasses="head-text sm" page="contact_us" />

                <div className="section contact-details-section">
                    <Container>

                        <div className="main-section d-flex">

                            <div className="text-block">
                                <div className="section-heading">Visit Us</div>
                                <div className="text">
                                    G 59, Marina Arcade,Middle Circle, <br />
                                    Connaught Place, New Delhi – 110001<br />
                                    Nearest Metro – Rajiv Chowk, Gate No. 7
                                </div>

                                <div className="section-heading">Email Us</div>
                                <div className="text">info@studyfeeds.com</div>

                                <div className="section-heading">Follow Us</div>
                                <ul className="list-inline d-flex social-icons">
                                    <li><Link to={{ pathname: "https://www.facebook.com/studyfeeds/" }} target="_blank"><i className="ico facebook"></i></Link></li>
                                    <li><Link to={{ pathname: "https://twitter.com/studyfeeds/" }} target="_blank"><i className="ico twitter"></i></Link></li>
                                    <li><Link to={{ pathname: "https://www.youtube.com/channel/UCE63iwDEr6zHxSz9MOJCAyQ" }} target="_blank"><i className="ico youtube"></i></Link></li>
                                    <li><Link to={{ pathname: "https://www.instagram.com/studyfeeds.in/" }} target="_blank"><i className="ico instagram"></i></Link></li>
                                </ul>
                            </div>

                            <div className="map-block">
                                <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=G%2059%2C%20Marina%20Arcade%2C%20Middle%20Circle%2C%20Connaught%20Place%2C%20New%20Delhi%20%E2%80%93%20110001&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="G 59, Marina Arcade, Middle Circle, Connaught Place, New Delhi – 110001" aria-label="G 59, Marina Arcade, Middle Circle, Connaught Place, New Delhi – 110001"></iframe>
                            </div>
                        </div>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default Contact;