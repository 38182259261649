import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, Button, AppBar, Card, CardContent, CardActionArea } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import AccountWrapper from '../AccountWrapper';

import Api from '../../../api/Api';

class TestInstructions extends Component { 
    constructor(props) {
        super();
        this.state = {

            start_exam: false,

            user: false,
            sf_student_token: false,

            loading: true,

            exam_id: false,
            type: '',

            exam: false,
            category_exams: [],

            back: false
        }
    }

    componentDidMount = () => {

        // var exam_id = this.props.exam_id;
        var type = this.props.type;

        var sf_student_token = localStorage.getItem('sf_student_token');
        var user = localStorage.getItem('user');

        if(sf_student_token){

            user = JSON.parse(user);

            this.setState({sf_student_token: sf_student_token, user: user});
            
            if(type){

                this.setState({type: type});
                this.loadExam(sf_student_token, type);

            }else{
    
                this.setState({back: true});
            }

        }else{

            window.location = Api.url + 'logout';
        }
    }

    render () {

        if(this.state.back){

            return <Redirect to="/account" />
        }

        if(this.state.start_exam && this.state.exam){

            return <Redirect to={"/account/test/running/" + this.state.exam.row_id} />
        }

        return (

            <AccountWrapper page_title="Instructions">

                <div className="exam-wrapper">

                    <div className="exam-header">
                        <div className="exam-header-container">

                            {this.state.exam
                                ?
                                    <>
                                        <h2>TestAS Prep test | Core test</h2>

                                        <div className="time">
                                            <span>Total Working time:</span>
                                            <strong>110 min</strong>
                                        </div>
                                    </>
                                :
                                    <>
                                        <Skeleton animation="wave" variant="rect" width={200} height={30} style={{borderRadius:30, backgroundColor:'rgba(255,255,255,.6)'}} />
                                        <Skeleton animation="wave" variant="rect" width={200} height={30} style={{borderRadius:30, backgroundColor:'rgba(255,255,255,.6)'}} />
                                    </>
                            }
                        </div>
                    </div>

                    <div className="exam-body">

                        <div className="exam-main intro-screen">

                            {this.state.exam
                                ?
                                    <div className="exam-container">
                                    
                                        <div className="exam-instructions">

                                            <h4>Please read the following instructions carefully:</h4>
                                            <div className="c-r">Everyone has to do the Core Test and it has to be completed first.</div>

                                            <ul>
                                                <li>
                                                    <span>1</span>
                                                    <div>The Core Test is divided in four different subtests.</div>
                                                </li>
                                                <li>
                                                    <span>2</span>
                                                    <div>You have a total of 110 minutes to solve the tasks.</div>
                                                </li>
                                                <li>
                                                    <span>3</span>
                                                    <div>In the table below you can see how many tasks there are in each subtest and how much time is allowed.</div>
                                                </li>
                                                <li>
                                                    <span>4</span>
                                                    <div>To prepare for this, there are six tasks to solve for each subtest on the following pages. The tasks at the beginning are easier than those at the end. At the beginning of each subtest there is a short explanation about the type of the tasks, together with instructions on how to solve the tasks.</div>
                                                </li>
                                                <li>
                                                    <span>5</span>
                                                    <div>You can find the solutions starting at page 53.</div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="d-flex justify-center mt-30">

                                            <Button variant="outlined" color="primary">Back</Button>
                                            <Button onClick={() => {

                                                this.setState({start_exam: true})
                                            }} variant="contained" color="primary">Continue</Button>
                                        </div>
                                    </div>
                                :
                                    <div className="exam-container">
                                    
                                        <div className="exam-instructions">

                                            {this.renderSkeleton()}
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="exam-sidebar">

                            {this.state.category_exams && this.state.category_exams.length > 0
                                ?

                                    <>

                                        {this.state.category_exams.map((_category_exam, index) => {

                                            return (
                                                <Card key={"exam_category_" + index} className="exam-info-card">

                                                    <CardContent>

                                                        <h4>{_category_exam.category_title}</h4>
                                                        <span className="time">{_category_exam.duration} Mins</span>

                                                        <span className="text">Amount of tasks - {_category_exam.tasks_count}</span>
                                                    </CardContent>
                                                </Card>
                                            )
                                        })}
                                    </>
                                :
                                    <>
                                        <Skeleton animation="wave" variant="rect" width={'100%'} height={130} style={{borderRadius:20, backgroundColor:'rgba(255,255,255,.8)', marginBottom:15}} />
                                        <Skeleton animation="wave" variant="rect" width={'100%'} height={130} style={{borderRadius:20, backgroundColor:'rgba(255,255,255,.8)', marginBottom:15}} />
                                        <Skeleton animation="wave" variant="rect" width={'100%'} height={130} style={{borderRadius:20, backgroundColor:'rgba(255,255,255,.8)', marginBottom:15}} />
                                        <Skeleton animation="wave" variant="rect" width={'100%'} height={130} style={{borderRadius:20, backgroundColor:'rgba(255,255,255,.8)', marginBottom:15}} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </AccountWrapper>
        )
    }

    renderSkeleton = () => {

        return (
            <div style={{width:'100%', minHeight:400, top:102, left:0, zIndex:999}}>
                <div>
                    <Skeleton animation="wave" variant="rect" width={'70%'} height={30} style={{borderRadius:30}} />
                    <Skeleton animation="wave" variant="rect" width={'50%'} height={30} style={{borderRadius:30, marginTop:15}} />

                    <Skeleton animation="wave" variant="rect" width={'100%'} height={20} style={{borderRadius:30, marginTop:35}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={20} style={{borderRadius:30, marginTop:15}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={20} style={{borderRadius:30, marginTop:15}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={20} style={{borderRadius:30, marginTop:15}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={20} style={{borderRadius:30, marginTop:15}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={20} style={{borderRadius:30, marginTop:15}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={20} style={{borderRadius:30, marginTop:15}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={20} style={{borderRadius:30, marginTop:15}} />
                </div>
            </div>
        )
    }

    loadExam = (sf_student_token, type) => {

        const formData = new FormData();

        var that = this;

        this.setState({loading_tests: true});

        formData.append('sf_student_token', sf_student_token);
        // formData.append('exam_id', exam_id);
        formData.append('type', type);

        Api.post('exams/load_exam', formData, function(data){
            
            if(data.status){

                that.setState({exam: data.exam, category_exams: data.category_exams, loading: false});
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    localStorage.setItem('temp_error', data.message);
                    that.setState({back: true});
                }
            }
        });
    }
}

export default TestInstructions;