import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import Bannerwithform from '../components/sections/Bannerwithform';

import Main from '../components/Main';

class DuringStudyGermany extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <Main page="test_as_main" className="during-study">
                <Bannerwithform page="enquiry" title="During studies in Germany" />

                <div className="section">
                    <Container>
                        
                        <div className="main-section">

                            <div className="list-heading">Please take note of the following things:</div>
                            <List className="options-listing" component="nav" aria-label="main options">
                                <ListItem>
                                    <ListItemIcon>1.</ListItemIcon>
                                    <ListItemText>Although, <b>no tuition fee</b> is applicable but there is a <b>semester fee</b> ranging between 100-350 euros/Semester. It covers a semester ticket and subsidized rates at student cafeterias and other student benefits for the entire semester. With a semester ticket, the student can travel throughout his state for free in public transportation including Bus, Tram, Metro, Trains, etc). It <b>excludes</b> high speed trains called ICE (like we have Rajdhani in India).</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>2.</ListItemIcon>
                                    <ListItemText>Most of the programs last 3 years, i.e. 6 semesters; including the Engineering programs. Few universities offer 7 and 8 semester programs as well.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>3.</ListItemIcon>
                                    <ListItemText>Duration of Medicine is 6 Years and 3 months. Medicine is divided into 2 stages. Pre-clinical lasting 2 years and 3 months of internship followed by 3 years of clinical studies and one year of internship. Dentistry lasts 10 semesters.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>4.</ListItemIcon>
                                    <ListItemText>All the universities across Europe follow the ECTS system (European Credit Transfer System). Each module/subject has a particular credit point as per the hours of study involved in terms of Hours during that semester. The study hours also include hours spent in labs and on industrial visits related to the subject.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>5.</ListItemIcon>
                                    <ListItemText>Each semester comprises 30 ECTS credits. Hence a 6 semester bachelor’s program comprises 180 ECTS credits.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>6.</ListItemIcon>
                                    <ListItemText>The student can switch to a higher semester for the same study program and all their previously attained credits would be recognized by the new university. For example a student who has completed 60 credits or 2 semesters at University A in B. Sc. Computer Science can directly begin semester 3 in B. Sc. Computer Science in University B. The student can in fact even study their 6 semesters at 6 different universities <b>across Europe</b>.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>7.</ListItemIcon>
                                    <ListItemText>If a student studying B. Sc. Computer Science at University A wishes to switch to B. Sc. Mechanical Engineering at University A or University B; then the subjects which were identical in B. Sc. Computer Science and B.Sc. Mechanical Engineering will be credited and he does not have to repeat them again. Only the different modules have to be cleared.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>8.</ListItemIcon>
                                    <ListItemText>The student can take 1-2 semester breaks during his/her studies on the pretext of traveling, doing some internship, studying abroad, etc. They just need to inform the university in writing if they are opting for a semester break.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>9.</ListItemIcon>
                                    <ListItemText>The study duration is not restricted and can be extended or reduced depending on the number of subjects a student is willing to study during a semester. For example, a student can also study 3 subjects instead of registering for all the 6 subjects prescribed by the university in that particular semester.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>10.</ListItemIcon>
                                    <ListItemText>Studies are flexible, a student enrolled in the first semester can also register for the subjects offered in 5th or 6th semester and vice-versa. They can also register for the subjects (called Modules in Germany) from other degree programs and if they clear the examination in that module, they’ll get the certificate of the same.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>11.</ListItemIcon>
                                    <ListItemText>Part time work – 240 Half days or 120 Full days during a calendar year. Roughly equal to 20 Hours/week. (1 Half day = 4 Hours, 1 Full day = 8 hours).</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>12.</ListItemIcon>
                                    <ListItemText>Generally students work in Restaurants, Filling Stations, Packaging Industries, Amazon BPO’s, etc. for their part time jobs.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>13.</ListItemIcon>
                                    <ListItemText>Part time wages – Minimum is 9.82 euros/hour from 01.01.2022. Generally students with English language skills get 10-11 euros/hour. Students working as research assistants in the University get around 400-450 euros/month for work of 20-40 hours during the month and it will not be deducted from the total allowance of 120 Full days or 240 Half days.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>14.</ListItemIcon>
                                    <ListItemText>Living expenses range between 550 euros to 750 euros per month depending on the city. The given estimated living expenses include your rent, food, compulsory health insurance, traveling, etc. Leisure traveling and other leisure activities are not included. Munich is the most expensive city closely followed by Hamburg and Berlin. Cheap cities include smaller towns like Chemnitz, Siegen, Aachen, etc.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>15.</ListItemIcon>
                                    <ListItemText>Health insurance costs approx 100 euros/month if taken through government providers TK and AOK only till the age of 30. Private insurance costs 50-90 euros/month till the student turns 30 but not all the medical facilities are covered by them. Dental is not covered by any health insurance company, whether public or private. The health insurance premium will increase considerably post the age of 30 and gradually increases with age.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>16.</ListItemIcon>
                                    <ListItemText>All the lectures, weekly class schedules, examination schedules, results, notifications, etc. from the university will be passed on to the students through the university’s own portal called Moodle. Every student will get a University’s email ID to be used during his studies and to access the Moodle. The student registers for the Modules/subjects he wants to study during his particular semester through the Moodle account only.</ListItemText>
                                </ListItem>
                            </List>
                            
                        </div>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default DuringStudyGermany;