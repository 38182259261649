import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Card, CardHeader, CardContent, Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { Check } from '@material-ui/icons';

import cardimage from '../../../assets/images/testAS.png';

import Loader from '../../../components/Loader';

import Api from '../../../api/Api';

class PurchasedTestCard extends Component { 
    constructor(props) {
        super();
        this.state = {

            qty: 1,
            modalOpen: false,

            loading: false,

            purchased_exam_row_id: '',
            type: ''
        }
    }

    handleIncrement = () => {

        this.setState({qty: this.state.qty + 1});
    }
    
    handleDecrement = () => {

        if(this.state.qty > 1){
        
            this.setState({qty: this.state.qty - 1});
        }
    }
    
    render () {

        // if(this.state.purchased_exam_row_id != '' && this.state.type != ''){

        //     return <Redirect to={"/account/test/instructions/" + this.state.purchased_exam_row_id + '/' + this.state.type} />
        // }

        if(this.state.type != ''){

            return <Redirect to={"/account/test/instructions" + '/' + this.state.type} />
        }

        if(this.props.test){

            var main_test = this.props.test;
        
            return (
                
                <div className="mt-20">
                    <CardHeader className="mb-20" title={main_test.title} />

                    {main_test.tests && main_test.tests.map((_test, _index) => {

                        return (
                            <Card className="card" style={{position:'relative'}} key={"exam_card_" + main_test.exam_id + '_' + _test.type + '_' + _index}>
                                <CardContent className="mb-20">
                                    <div className="d-flex">
                                        {/* <div className="img-block">
                                            <img src={_test.image} alt="Image" />
                                        </div> */}
                                        <div className="details-block">
                                            <div className="title">{_test.type == 'core' ? 'Core Test' : main_test.category_title}</div>
                                            <div className="text">{main_test.description}</div>

                                            {_test.type == 'core'
                                                ?
                                                    <List className="check-list">
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <Check />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Solving Quantitative Problems" />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <Check />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Inferring Relations" />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <Check />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Completing Patterns" />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <Check />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Continuing Numerical Series" />
                                                        </ListItem>
                                                    </List>
                                                :
                                                    <List className="check-list">
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <Check />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Analysing Economic Interrelationships" />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <Check />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Analysing Processes" />
                                                        </ListItem>
                                                    </List>
                                            }
                                        </div>
                                        <div className="results-block">
                                            <div className="blocks-section">
                                                <div className="block">
                                                    <div className="text">Tests</div>
                                                    <div className="num red-txt">{main_test.count}</div>
                                                </div>
                                                <div className="block">
                                                    <div className="text">Attempted</div>
                                                    <div className="num green-txt">{_test.attempted}</div>
                                                </div>
                                            </div>
                                            <div className="results-section">
                                                <div className="title">Results</div>

                                                {_test.tests && _test.tests.length
                                                    ?

                                                        _test.tests.map((_sub_test, index) => {

                                                            if(_sub_test.attempted == 1){

                                                                return <div key={"result_row_" + main_test.exam_id + _index + '_' + index} className="">{_sub_test.correct_answers}/{_sub_test.total_questions}</div>
                                                            }
                                                        })
                                                    :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <div className="buttons-block sm">
                                            <div className="title">Action</div>

                                            {/* {_test.tests && _test.tests.length
                                                ?

                                                    _test.tests.map((_sub_test, index) => {

                                                        if(_sub_test.attempted == 1){

                                                            return <Button key={"result_action_" + main_test.exam_id + _index + '_' + index} className="btn btn-success-blur" onClick={() => {

                                                                this.props.showResult()
                                                            }}>View Result</Button>
                                                        }
                                                    })
                                                :
                                                    null
                                            } */}
                                            
                                            {main_test.count > _test.attempted &&
                                                <Button onClick={() => {

                                                    this.startExam(main_test.purchased_exam_row_id, _test.type)
                                                }} className="btn btn-danger">Take Test</Button>
                                            }
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        )
                    })}
                    <Loader loading={this.state.loading} />
                </div>
            )
        }else{

            return <></>
        }
    }

    startExam = (purchased_exam_row_id, type) => {

        this.setState({purchased_exam_row_id: purchased_exam_row_id, type: type});
    }
}

export default PurchasedTestCard;