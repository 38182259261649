import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { Container } from '@material-ui/core';

import bannerimage from '../../assets/images/home-banner.jpg';
import Api from '../../api/Api';

class Banners extends Component { 
    constructor(props) {
        super();
        this.state = {

            course_price: 100,
            course_special_price: 90
        }
    }

    componentDidMount = () => {

        var sf_student_token = localStorage.getItem('sf_student_token');
        var user = localStorage.getItem('user');

        if(sf_student_token){

            user = JSON.parse(user);

            this.setState({sf_student_token: sf_student_token, user: user});
        }

        this.init();
    }

    render () {

        return (
            <div className="banner-slider">
                <div className="banners-block">
                    <div className="bg-block">
                        <img className="banner-image" src={bannerimage} alt="Banner Image" />
                    </div>
                    <Container>
                        <div className="block">
                            <div className="head-text">TestAS Preparation Test</div>
                            <div className="subhead-text">Approach the TestAS Exam with confidence</div>
                            <div className="description">TestAS is an exclusive program designed for school students looking for direct admissions in Germany in course of their choice after completing their 12th in India or abroad.</div>
                            <div className="links">

                                {this.state.sf_student_token
                                    ?
                                        <Link to="/account/tests" className="btn">
                                            <span className="text">Buy Now</span>
                                            <span className="price-text">€{this.state.course_special_price} <strike>€{this.state.course_price}</strike> /-</span>
                                        </Link>
                                    :
                                        <Link to="/signin" className="btn">
                                            <span className="text">Buy Now</span>
                                            <span className="price-text">€{this.state.course_special_price} <strike>€{this.state.course_price}</strike> /-</span>
                                        </Link>
                                }
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        )
    }

    init = () => {

        const formData = new FormData();

        var that = this;

        Api.post('general/init', formData, function(data){
            
            that.setState({course_price: data.course_price, course_special_price: data.course_special_price});
        });
    }
}

export default Banners;