import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Container, Modal, LinearProgress } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import Main from '../../../components/Main';

import bannerimage from '../../../assets/images/admin-banner.png';
import cardimage from '../../../assets/images/testAS.png';

import TestListingCard from '../blocks/TestListingCard';

import Api from '../../../api/Api';

import LayoutHelper from '../../../helpers/LayoutHelper';

class TestsList extends Component { 
    constructor(props) {
        super();
        this.state = {

            error_message: '',
            success_message: '',

            modalOpen: false,

            user: false,
            sf_student_token: false,

            loading_tests: false,
            tests: [],

            purchased: false
        }
    }

    componentDidMount = () => {

        // const script = document.createElement('script');
        // script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        // script.async = true;
        // document.body.appendChild(script);

        var sf_student_token = localStorage.getItem('sf_student_token');
        var user = localStorage.getItem('user');

        if(sf_student_token){

            user = JSON.parse(user);

            this.setState({sf_student_token: sf_student_token, user: user});
            this.loadTests(sf_student_token);

        }else{

            window.location = Api.server_url + 'logout';
        }
    }

    handleModalOpen = () => {
        this.setState({modalOpen: true});
    }
    
    handleModalClose = () => {
        this.setState({modalOpen: false});
    }

    render () {

        if(this.state.purchased){

            return <Redirect to="/account" />
        }

        return (

            <Main page="account" error_message={this.state.error_message} success_message={this.state.success_message}>

                <div className="account test-results">

                    <div className="banner-block">
                        <img className="banner-img" src={bannerimage} />
                        <Container className="container">
                            <div className="txt">We Learn by <span className="yellow-txt">practice</span></div>
                        </Container>
                    </div>

                    <Container>

                        <div className="space-between">
                        
                            <div className="welcome-txt">Welcome, {this.state.user.name}</div>
                        </div>
                    </Container>

                    <Container className="cards-listing">

                        {this.state.tests && this.state.tests.length > 0
                            ?
                                this.state.tests.map((_test) => {

                                    return (
                                        <TestListingCard
                                            key={"test_" + _test.id}    
                                            sf_student_token={this.state.sf_student_token}
                                            test={_test}
                                            user={this.state.user}
                                            successMessage={(message) => {
                                                
                                                LayoutHelper.addSuccessMessage(this, message)
                                            }}
                                            errorMessage={(message) => {
                                                
                                                LayoutHelper.addErrorMessage(this, message)
                                            }}
                                            onSuccess={() => {

                                                window.setTimeout(() => {

                                                    this.setState({purchased: true})
                                                }, 2000);
                                            }}
                                            updateTests={(test) => {

                                                var tests = this.state.tests;

                                                for(var i in tests){

                                                    var single_test = tests[i];

                                                    if(single_test.id == test.id){

                                                        tests[i]['price'] = test['price'];
                                                        tests[i]['special_price'] = test['special_price'];
                                                        tests[i]['purchase_price'] = test['purchase_price'];
                                                    }
                                                }

                                                this.setState({tests: tests});
                                            }}
                                        />
                                    )
                                })
                            :
                                null
                        }

                        {this.state.loading_tests &&
                            this.renderSkeleton()
                        }
                        
                    </Container>

                    <Modal className="modal md" open={this.state.modalOpen} onClose={this.handleModalClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <div className="modal-block">
                            <h2 className="modal-title">
                                <span className="red-txt">TestAS Preparatory Test</span> | Economic Module
                                <span className="btn btn-blk btn-close" onClick={this.handleModalClose}>X</span>
                            </h2>

                            <div className="description d-flex">
                                <div className="left-block">
                                    <div className="top">
                                        <div className="section first">
                                            <div className="heading">Subtest / Test Module</div>
                                        </div>

                                        <div className="section first">
                                            <div className="heading">Percentile Rank</div>
                                        </div>

                                        <div className="section first">
                                            <div className="heading">Standard Score</div>
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="section first">
                                            <div className="details-listing">
                                                <div className="text">Solving Quantitative Problems (SQP)</div>
                                                <div className="text">Inferring Relationships (IR)</div>
                                                <div className="text">Completing Patterns (CP)</div>
                                                <div className="text">Continuing Numerical Series (CNS)</div>
                                                <div className="text">Core Test</div>
                                                <div className="text">Analysing Economic Interrelationships (AEI)</div>
                                                <div className="text">Analysing Processes (AP)</div>
                                                <div className="text">Economics Module (Economics)</div>
                                            </div>
                                        </div>

                                        <div className="section second">
                                            <div className="details-listing">
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                            </div>
                                        </div>

                                        <div className="section third">
                                            <div className="details-listing">
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                                <div className="text">80</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="right-block">
                                    <div className="heading">Percentile Ranks</div>
                                    <div className="test-progress-listing">
                                        <div className="section">
                                            <div className="text">Core Test</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="84" />
                                                <span className="num">84</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">Economics</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="97" />
                                                <span className="num">97</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">SQP</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="86" />
                                                <span className="num">86</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">IR</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="79" />
                                                <span className="num">79</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">CP</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="58" />
                                                <span className="num">58</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">CNS</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="86" />
                                                <span className="num">86</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">AEI</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="84" />
                                                <span className="num">84</span>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="text">CNAPS</div>
                                            <div className="progress">
                                                <LinearProgress variant="determinate" value="96" />
                                                <span className="num">96</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </Main>
        )
    }

    loadTests = (sf_student_token) => {

        const formData = new FormData();

        var that = this;

        this.setState({loading_tests: true});

        formData.append('sf_student_token', sf_student_token);

        Api.post('exams/listing', formData, function(data){
            
            if(data.status){

                that.setState({tests: data.tests, loading_tests: false});
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }

    renderSkeleton = () => {

        return (
            <div style={{width:'100%', minHeight:400, top:102, left:0, zIndex:999}}>
                <div>
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={230} style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={200} className="mt-10" style={{borderRadius:10}} />
                </div>
            </div>
        )
    }
}

export default TestsList;