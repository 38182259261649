import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import Bannerwithform from '../components/sections/Bannerwithform';

import Main from '../components/Main';

class BlockedAccount extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <Main page="test_as_main" className="blocked-account">
                <Bannerwithform page="enquiry" title="Blocked Account" />

                <div className="section">
                    <Container>
                        <div className="main-section">
                            <p>Every student who’s willing to study in Germany needs to have a blocked account worth 10356 Euros with a German bank. The student can withdraw a maximum of 863 Euros/month from this blocked account to pay for their living expenses. This blocked account is required at the time of visa application. We assist the students with opening up the blocked account.</p>

                            <div className="list-heading mt-2">Students belonging to the following category do not have to open a blocked account:</div>
                            <List className="options-listing" component="nav" aria-label="main options">
                                <ListItem>
                                    <ListItemIcon>1.</ListItemIcon>
                                    <ListItemText>PhD students receiving stipends.</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>2.</ListItemIcon>
                                    <ListItemText>Scholarship holders</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>3.</ListItemIcon>
                                    <ListItemText>Students having “Verpflichtungserklärung” (formal obligation letter) by sponsor living in Germany</ListItemText>
                                </ListItem>
                            </List>

                        </div>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default BlockedAccount;