import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Grid, FormControl, TextField, InputAdornment, Button, CircularProgress } from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

import { EmailOutlined, LockOutlined } from '@material-ui/icons';

import { connect } from 'react-redux';
import {User} from '../../actions/user';

import Api from '../../api/Api';

import ValidatorHelper from '../../helpers/ValidatorHelper';
import LayoutHelper from '../../helpers/LayoutHelper';

import Main from '../../components/Main';

import AuthSidebar from './AuthSidebar';

class ResetPassword extends Component { 
    constructor(props) {
        super();
        this.state = {

            dashboard: false,
            login: false,

            loading: false,

            error_message: '',
            success_message: '',

            password: '',
            confirm_password: '',
            
            password_error: false,
            confirm_password_error: false,
            
            status_error: '',
            status_success: '',

            user: false,
            user_token: false,

            redirect: false,
            logout: false,

            code: false
        }
    }

    componentDidMount = () => {

        var code = this.props.code;

        if(code){

            this.setState({code: code});
        }else{

            this.setState({logout: true});
        }
    }

    render () {

        if(this.state.redirect){
            return <Redirect to="/signin" />
        }

        if(this.state.logout){
            return <Redirect to="/logout" />
        }

        return (

            <Main sidebar={false} full_width={true} success_mesasge={this.state.success_mesasge} error_message={this.state.error_message}>

                <div page="login" className="login-main-page">
                    <div className="container">
                        <div className="login-page d-flex items-end">
                            
                            <AuthSidebar />

                            <div className="right half-container self-right auth-block" style={{position:'relative'}}>

                                <div className="section-heading">Reset Password</div>
                                <p>Please create a new password for your account</p>

                                {this.state.status_success &&
                                    <Alert severity="success" className="mb-30" onClose={() => {this.setState({status_success: false})}}>
                                        <AlertTitle>Success</AlertTitle>
                                        {this.state.status_success}
                                    </Alert>
                                    
                                }

                                {this.state.status_error &&
                                    <Alert severity="error" className="mb-30" onClose={() => {this.setState({status_error: false})}}>
                                        <AlertTitle>Error</AlertTitle>
                                        {this.state.status_error}
                                    </Alert>
                                }

                                <form onSubmit={this.loginSubmit.bind(this)} id="register_form">
                                    <Grid container>
                                        <Grid className="mb-30" item xs={12} sm={12}>
                                            
                                            <FormControl fullWidth={true}>
                                                <TextField value={this.state.password} onChange={(v) => {
                                                    this.setState({password: v.target.value})
                                                }} className="filled-input" name="password" placeholder="Password" variant="outlined" InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LockOutlined />
                                                        </InputAdornment>
                                                    ),
                                                }} error={this.state.password_error} helperText={this.state.password_error ? 'Password is required. Please enter min 6 characters password.' : ''} type="password" onBlur={(e) => {
                                                    if(e.target.value != ''){
                                                        this.setState({password_error: false})
                                                    }
                                                }} />
                                            </FormControl>
                                        </Grid>
                                        <Grid className="mb-30" item xs={12} sm={12}>
                                            
                                            <FormControl fullWidth={true}>
                                                <TextField value={this.state.confirm_password} onChange={(v) => {
                                                    this.setState({confirm_password: v.target.value})
                                                }} className="filled-input" name="confirm_password" placeholder="Confirm Password" variant="outlined" InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LockOutlined />
                                                        </InputAdornment>
                                                    ),
                                                }} error={this.state.confirm_password_error} helperText={this.state.confirm_password_error ? 'Confirm password must be same as password' : ''} type="password" onBlur={(e) => {
                                                    if(e.target.value != ''){
                                                        this.setState({confirm_password_error: false})
                                                    }
                                                }} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>

                                            <div className="space-between">

                                                <div className="align-center" style={{position:'relative'}}>
                                                    <Button type="submit" variant="contained" color="primary">Update</Button>
                                                    {this.state.loading && <CircularProgress size={24} />}
                                                </div>

                                                <Link className="ml-10" to="/signin">Back to login</Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        )
    }

    loginSubmit = (event) => {

        event.preventDefault();
        const formData = new FormData(event.target);

        var _has_error = false;

        var password = formData.get('password');
        var confirm_password = formData.get('confirm_password');

        if(password == '' || password.length < 6){
            this.setState({password_error: true});
            _has_error = true;
        }else{
            this.setState({password_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(confirm_password == '' || password != confirm_password){
            this.setState({confirm_password_error: true});
            _has_error = true;
        }else{
            this.setState({confirm_password_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(!_has_error){

            var that = this;
            this.setState({loading: true});

            formData.append('code', this.props.code);
        
            Api.post('auth/reset_password', formData, function(data){
            
                that.setState({loading: false, password: '', confirm_password: ''});

                if(data.status == true){
                    
                    that.setState({status_success: data.message});
                    window.setTimeout(() => {

                        that.setState({redirect: true});
                    }, 2000);

                }else{

                    LayoutHelper.addErrorMessage(that, data.message);
                }
            });
        }
    }
}

const mapStateToProps = state => {
	return {
        user: state.user.user
	}
}

export default connect(mapStateToProps, { User } )(ResetPassword);
