import React, { Component } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

class FaqsAccordion extends Component {
  constructor(props) {
      super();
      this.state = {

          expanded: 'panel1',
          expandedIcon: 'add',
          prependedIcon: 'remove',
      }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {

    return (
      <div className="root">
        <Accordion expanded={this.state.expanded === 'panel1'} onChange={this.handleChange('panel1')}>
          <AccordionSummary expandIcon={<Icon>{this.state.expanded === 'panel1'? this.state.prependedIcon: this.state.expandedIcon}</Icon>}>
            <Typography className="heading">What is the TestAS?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>The Test for Academic Studies (TestAS) is an official standardized exam offered to high school graduates from non-European countries who wish to study at a German university. It is offered by the Gesellschaft für Akademische Studienvorbereitung und Testentwicklung e.V. (g.a.s.t. = Society for Academic Study Preparation and Test Development, a Registered Association).</p>
              <p>The TestAS is a voluntary, standardized test that examines a student’s cognitive and intellectual abilities. It measures a student’s academic aptitude, and has been used since 2007 to assist in university applications and approvals. A TestAS result is used to help gain admission into a German university. The test can be taken in German or English and in certain cases in Arabic.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={this.state.expanded === 'panel2'} onChange={this.handleChange('panel2')}>
          <AccordionSummary expandIcon={<Icon>{this.state.expanded === 'panel2'? this.state.prependedIcon: this.state.expandedIcon}</Icon>}>
            <Typography className="heading">Why should I take the TestAS? (Advantages of TestAS)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>The TestAS allows students to realistically assess their chances of successfully completing a degree at a German university. The results of the test inform each participant about their individual strengths and weaknesses, and indicates their likely progress in an academic course. This is useful information for students, as it helps to guide them in selecting an appropriate area of study that matches their individual abilities.</p>
              <p>Scoring well on the TestAS gives applicants at some universities an advantage during the selection process (for a list of universities, please see: Which universities currently accept the TestAS results?). German universities are able to compare and assess the abilities of potential students, which informs the selection of students into university courses. Therefore, a good test result can improve the chances of the applicant gaining entry into their desired study course and university. The results also increase the likelihood of a student completing their chosen course of studies, rather than dropping out if the course is too difficult for their abilities.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={this.state.expanded === 'panel3'} onChange={this.handleChange('panel3')}>
          <AccordionSummary expandIcon={<Icon>{this.state.expanded === 'panel3'? this.state.prependedIcon: this.state.expandedIcon}</Icon>}>
            <Typography className="heading">Who can take the TestAS?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>The TestAS is intended for foreign students to gain admission into an undergraduate degree at a German university, including a Bachelor, Magister, Diplom or State Examination. There is no age limit, however many participants have completed high school. Applicants need to have at least B1 level language skills in German and/or English. One is allowed to take the test with a lower language level; but it would be difficult to score higher than the average participant. The questions tend to be long, testing text comprehension skills among other things.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={this.state.expanded === 'panel4'} onChange={this.handleChange('panel4')}>
          <AccordionSummary expandIcon={<Icon>{this.state.expanded === 'panel4'? this.state.prependedIcon: this.state.expandedIcon}</Icon>}>
            <Typography className="heading">Recognition of TestAS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>TestAS is recognised by German universities that have included the TestAS into their admission requirements. The recognition of TestAS results varies between each university. While some universities require the TestAS as a pre-requisite, other universities only recommend it. The system by which the universities weigh each TestAS section result is also determined individually by the universities.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={this.state.expanded === 'panel5'} onChange={this.handleChange('panel5')}>
          <AccordionSummary expandIcon={<Icon>{this.state.expanded === 'panel5'? this.state.prependedIcon: this.state.expandedIcon}</Icon>}>
            <Typography className="heading">Which universities currently accept the TestAS results?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>In the following, you will find a list of universities (as of February 2017) which recognise this test for foreign students. This list is without guarantee due to the fact that the TestAS and its acceptance at German universities is continuously changing and expanding. Please refer to the websites of the universities for up-to-date information.</p>
              <p>In most cases, taking the test as part of the application process is voluntary. In these cases, the participant can decide whether or not the results should be made available to the university on an individual basis (if the results are not as high as desired).</p>
              <p>If you have a higher than average TestAS result, the university entrance qualification (HZB) score may be increased. More information about the HZB score can be found online, e.g. at the website of uni-assist e.V.:  http://www.uni-assist.de/wissenswertes.html</p>
              <p>The test is normally only relevant for non-EU applicants who have not received their HZB score in Germany and who are applying for a Bachelor’s programme in Germany.</p>
              
              <p><b>FOR THE FOLLOWING UNIVERSITIES, TESTAS PARTICIPATION IS MANDATORY FOR SPECIFIC FIELDS OF STUDY:</b></p>
              <p>The University of Cologne only accepts applications (except in the case of law and teaching education programmes) which contain TestAS results. The TestAS certificate must be submitted along with the application documents and sent to uni-assist e.V. Applicants must achieve a specific standard score within the TestAS in order to be able to apply. We have listed a few such examples below (as at 03.02.2017). Additional and more up-to-date information can be found on the website of the University of Cologne.</p>
              <p>When applying for the Faculty of Medicine, the average score required for the core test and the specialist module on Mathematics, Information Technology and Natural Sciences is 108.</p>
              <p>When applying for the Faculty of Economic and Social Sciences, the average score required for the core test and the specialist module on Economics is 108.</p>
              <p>When applying for law of for any teacher training programmes, the TestAS is currently not required.</p>
              <p>Source: http://international.uni-koeln.de/6944.html; last accessed on 03.02.2017.</p>
              
              <p>The RWTH Aachen University requires participants to take the TestAS if the participant is applying for the fields of Human Medicine or for the Fast Track Bachelor Admission Programme for Engineering. In the case of Human Medicine, the exam must be taken in German and must include the specialist module “Mathematics, Information Technologies and Natural Sciences” in addition to the core test. In the case of Fast Track Engineering, the specialist module on Engineering Sciences must be taken.</p>
              <p>Source: http://www.rwth-aachen.de/cms/root/Studium/Vor-dem-Studium/Studiengaenge/Liste-Aktuelle-Studiengaenge/Studiengangbeschreibung/~bncp/Medizin-Modellstudiengang-Staatsexamen/ ; last accessed on 03.02.2017.</p>
              
              <p>The University of Bremen only accepts applications for business programmes (Business Administration and Economics) when they include the TestAS results.</p>
              <p>When applying to other fields of study, the TestAS is voluntary. A significantly higher grade can be achieved by taking the voluntary TestAS. If a participant’s percentile rank within the core test is at least 50%, then the HZB score of the participant is raised by 0.3 points. If the participant achieves a percentile rank of at least 50% in the specialist module, then an additional 0.3 is awarded.</p>
              
              <p>Source: http://www.uni-bremen.de/international/wege-an-die-universitaet-bremen/grundstaendiges-studium-bewerbung.html ; http://www.uni-bremen.de/fileadmin/user_upload/single_sites/sfsi/Notenverbesserung_TestAS.pdf; last accessed on 02.02.2017.</p>
              
              <p>For Human Medicine applications at the Otto von Guericke University of Magdeburg, the taking of the TestAS (core test and specialist module on Mathematics, Information Sciences and Natural Sciences test) is mandatory. The exam has to be taken in German.</p>
              <p>To select applicants, this university applies a ranking procedure that takes into account HZB and TestAS scores. Further detailed information can be found under Paragraph 7, Point 5: http://www.bekanntmachungen.ovgu.de/media/Archiv+_+Amtliche+Bekanntmachungen/2016/28_2016+Satzung+zur+Durchf%C3%BChrung+des+Hochschulauswahlverfahrens+f%C3%BCr+ausl%C3%A4ndische+Studienbewerberinnen+und+Studienbewerber+f%C3%BCr+den+Studiengang+Medizin.pdf.</p>
              
              <p>Source: https://www.med.uni-magdeburg.de/aaaZulassung.html; last accessed on 09.02.2017.</p>
              
              <p>The University of Greifswald has made the taking of the TestAS mandatory for applications to the study programme of Human and Dental Medicine. To apply, the university requires a score of at least 100 points on the core test.</p>
              <p>Source: https://www.uni-greifswald.de/fileadmin/uni-greifswald/1_Universitaet/1.2_Organisation/1.2.5_Satzungen_und_Formulare/Satzungen/Studium_und_Pruefungen/Satzung_Auswahlvf_Ausl.pdf; last accessed on 03.02.2017</p>
              
              <p>Universities where applicants can greatly improve their HZB score due to good results (more than 0.5 “abitur” score):</p>
              
              <p>The University of Hamburg awards a significant number of bonus points based on the TestAS results. The following website shows an overview chart available for the bonus points system: https://www.uni-hamburg.de/campuscenter/studienorganisation/formulare-informationsmerkblaetter/zulassungskriterien-bewerbung-nicht-eu.pdf</p>
              <p>Source: https://www.uni-hamburg.de/campuscenter/bewerbung/international/studium-mit-abschluss/studierfaehigkeitstest.html; last accessed on 03.02.2017</p>
              
              <p>The Johannes Guttenberg University of Mainz awards bonus points for the HZB score based on the TestAS results. The most recently taken TestAS is the one that applies. The level of bonus points available (up to 0.5 for the core test and up to 0.5 for the specialist module) can be found here: https://www.studium.uni-mainz.de/testas/</p>
              <p>Source: https://www.international.uni-mainz.de/studierfaehigkeitstest-testas/; last accessed on 03.02.2017.</p>
              
              <p>Universities where applicants can increase their chances of acceptance with good results:</p>
              <p>The University of Heidelberg uses the TestAS as a minor voluntary part of their acceptance criteria.</p>
              <p>Source: http://www.uni-heidelberg.de//studium/interesse/int_bewerbung/unterlagen.html ; http://www.uni-heidelberg.de/md/studium/interesse/int_bewerbung/verfahren/2012-04-23_aktuelleinfo2012-13_internet.pdf; last accessed on 03.02.2017.</p>
              
              <p>At the Dresden University of Technology (TU Dresden), the TestAS results are used as additional proof of ability for applications to the study programmes of Human and Dental Medicine.</p>
              <p>Source: https://tu-dresden.de/med/mf/studium/angebot/bewimma/adh_nicht-eu; last accessed on 03.02.2017</p>
              
              <p>The University of Ulm adds bonus points to the HZB average score based on the TestAS results for a standard score of at least 100. The HZB score can be improved by up to 0.5 points.</p>
              <p>Standard score below 100: No bonus</p>
              <p>Standard score between 100 and 110: Bonus of 0.2</p>
              <p>Standard score between 111 and 120: Bonus of 0.3</p>
              <p>Standard score above 121 and 130: Bonus of 0.5</p>
              
              <p>Source: https://www.uni-ulm.de/fileadmin/website_uni_ulm/zuv/zuv.dezIII.abt2u3/3-2oeffentlich/bekanntmachungen/2015/zulassung_immaO_2015.pdf; last accessed on 03.02.2017.</p>
              
              <p>The University of Freiburg awards bonus points based on TestAS results. No additional information has yet been made available.</p>
              <p>Source: http://www.studium.uni-freiburg.de/studienbewerbung/international/vollzeitstudium; last accessed on 03.02.2017.</p>
              <p>The University of Göttingen uses the TestAS as a voluntary part of their acceptance criteria. Participation in the TestAS for the subject of Natural Sciences may increase your chances of being accepted.</p>
              <p>Source: https://www.uni-goettingen.de/de/511340.html; last accessed on 03.02.2017.</p>
              
              <p>The University of Leipzig adds bonus points to the HZB score based on TestAS results if the participant achieves a percentile rank of at least 70%. For a percentile rank higher than 70%, there is a bonus of 0.2. For similar results within the specialist module, an additional 0.2 bonus points are awarded.</p>
              
              <p>Source: https://www.zv.uni-leipzig.de/studium/studium-international/internationale-bewerber/bachelor-staatsexamen-diplom/testas.html; last accessed on 03.02.2017.</p>
              
              <p>The University of Potsdam takes “good” TestAS results into consideration by awarding such applicants an edge over other applicants with the same qualifications and no TestAS results.</p>
              <p>Source: https://www.uni-potsdam.de/studium/zugang/vor-bewerbung-immatrikulation/studienvorbereitende-kurse/; last accessed on 03.02.2017.</p>
              <p>The University of Regensburg takes the TestAS results into consideration for programmes in Economic Sciences. By submitting the TestAS results along with the application, the applicant can improve his/her chances of a place at the University of Regensburg.</p>
              <p>Source: http://www.uni-regensburg.de/zentrum-sprache-kommunikation/daf/pruefungen/testas/; last accessed on 03.02.2017.</p>
              
              <p>The Berlin School of Economics and Law gives out bonus points depending on the test results achieved by applicants for Economic Sciences.</p>
              <p>Source: http://www.hwr-berlin.de/fachbereich-wirtschaftswissenschaften/bewerbung/auswahl-und-einstufungstests/testas/; last accessed on 03.02.2017.</p>
              
              <p>The University of Applied Sciences Mittweida assesses an application positively when a TestAS certification is submitted alongside it.</p>
              <p>Source: https://www.studium.hs-mittweida.de/bewerbung/bewerbung-fuer-auslaendische-studieninteressenten.html; last accessed on 03.02.2017</p>
              
              <p>The Technical University of Telecommunications and Informatics Leipzig (HfTL) uses the TestAS as a voluntary part of their application criteria.</p>
              <p>Source: https://www.hft-leipzig.de/no_cache/en/international-office.html?cid=12308&did=7194&sechash=42b50bd1; last accessed on 03.02.2017.</p>
              
              <p>Universities which accept the TestAS as a proof of ABILITY (additional documents ARE required):</p>
              <p>The University of Wuppertal (BUW) accepts good TestAS results as proof of suitability for a Bachelor’s programme and, in such cases, does not require the standard university entrance qualification documents such as a high school diploma. It requires a standard score of at least 76 on the TestAS core test 76 and at least 83 on the specialist module.</p>
              <p>Source: http://www.internationales.uni-wuppertal.de/fileadmin/internationales/files/Allgemeine_Informationen.pdf; last accessed on 03.02.2017.</p>
              
              <p>The Technical University of Hamburg (TUHH) makes it possible for students with above-average TestAS results to apply for a technical subject that deviates from their HZB score. The applicant must have an adequate HZB score for study programmes in Engineering Sciences and their overall test result for the core test and specialist module in Engineering Sciences must be at least 100 points. Then, the subject-specific HZB may be expanded to a general HZB for the TUHH bachelor programmes.</p>
              <p>Example:</p>
              <p>“An applicant has university entrance qualification for electrical engineering, but would prefer to study civil and environmental engineering. They have passed the TestAS (core test + specialist module in Engineering Sciences) with an overall result of 106. The applicant is thus awarded the university entrance qualification for civil and environmental engineering on the part of TUHH.” (Quoted from the TUHH website listed below)</p>
              
              <p>Source: https://www.tuhh.de/tuhh/studium/bewerbung/bachelorstudiengaenge/bewerberinnen-mit-nicht-deutschem-abitur.html; last accessed on 03.02.2017.</p>
              
              <p>The Frankfurt School of Finance & Management accepts applications containing one of the following tests: TestAS, ACT or SAT.</p>
              <p>Source: http://www.frankfurt-school.de/content/en/education_programmes/education_advisory/application-admission-bachelor.html; last accessed on 03.02.2017.</p>
              
              <p>The Goethe University in Frankfurt am Main uses the TestAS as a voluntary part of their acceptance criteria for the one-semester programme “Academic Welcome Program for Highly Qualified Refugees”.</p>
              <p>Source: https://www.uni-frankfurt.de/60148516/03-Auswahlverfahren; last accessed on 03.02.2017.</p>
              
              <p>Additionally, there are universities such as the Augsburg University, which recommend taking the TestAS but do not take the results into consideration as part of the application process. (Source: https://www.hs-augsburg.de/fakultaet/aw/zsi/kod/testas/index.html ; last accessed on 03.02.2017.) A further example of this is the University of Applied Sciences Berlin. (Source: https://www.htw-berlin.de/international/internationale-htw-studierende/bewerbung-mit-auslaendischen-zeugnissen/test-fuer-auslaendische-studierende/ ; https://www.htw-berlin.de/fileadmin/HTW/Zentral/ZHV_IIIB_-_Zulassung_und_Immatrikulation/Bewerbungsunterlagen_internationale_Bewerber.pdf; last accessed on 03.02.2017.)</p>
              <p>For up-to-date information on whether and how the TestAS results are integrated into the application process, please visit the universities’ websites.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default FaqsAccordion;