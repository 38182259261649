import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import renderHTML from 'react-render-html';

import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { Button, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Popover } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import Countdown, {zeroPad} from 'react-countdown';

import AccountWrapper from '../AccountWrapper';
import Loader from '../../../components/Loader';

import TestReview from './TestReview';

import Api from '../../../api/Api';

function TestScreen(props){

    const handle = useFullScreenHandle();
    
    const [triggered, setTriggered] = useState(false);

    const [user, setUser] = useState(false);
    const [sf_student_token, setToken] = useState(false);

    const [loader, setLoader] = useState(false);
    
    const [exam_id, setExamId] = useState(false);

    const [exams_count, setExamsCount] = useState(0);
    const [exam, setExam] = useState(false);
    const [category_exam, setCategoryExam] = useState(false);
    const [sample_question, setSampleQuestion] = useState(false);
    const [total_questions, setTotalQuestions] = useState(0);

    const [total_time, setTotalTime] = useState(0);

    const [back, setBack] = useState(false);

    const [exam_started, setExamStarted] = useState(false);

    const [open, setOpen] = useState(false);

    const [attempted_questions, setAttemptedQuestions] = useState({});
    const [current_question, setCurrentQuestion] = useState(1);

    const [selected, setSelected] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    const [submit_dialog, setSubmitDialog] = useState(false);
    const [time_over, setTimeOver] = useState(false);

    useEffect(() => {
        
        var exam_id = props.exam_id;

        var sf_student_token = localStorage.getItem('sf_student_token');
        var user = localStorage.getItem('user');
        
        if(!triggered){

            setTriggered(true);
        
            if(sf_student_token){
            
                setToken(sf_student_token);

                user = JSON.parse(user);

                setUser(user);

                if(exam_id){

                    setExamId(exam_id);
                    loadExam(sf_student_token, exam_id, false);

                    // window.setTimeout(() => {

                    //     setExamStarted(true);
                    //     handle.enter();
                    // }, 2000);

                }else{
        
                    setBack(true);
                }

            }else{

                window.location = Api.url + 'logout';
            }
        }
    });

    const handleChanged = useCallback((state, _handle) => {

        if(exam_started){
        
            if(state == false){

                loadNextExam();
            }
        }

    }, [handle]);

    const renderQuestion = () => {

        if(exam_started){

            if(category_exam){

                var rendered_questions = category_exam.rendered_questions;

                if(rendered_questions.hasOwnProperty(current_question)){

                    var question = (rendered_questions[current_question]);
                
                    return (

                        <div style={{marginBottom:200}}>
                            <h4>{renderHTML(question.title)}</h4>
                            <p>{renderHTML(question.details)}</p>
                            {question.image != '' &&

                                <img src={question.image} style={{maxHeight:500, maxWidth:'100%', display:'block'}} />
                            }
                        </div>
                    )
                }
            }
        }else{

            if(sample_question){

                return (

                    <div style={{marginBottom:200}}>
                        <h5>Sample Question</h5>
                        <h4>{renderHTML(sample_question.title)}</h4>
                        <p>{renderHTML(sample_question.details)}</p>
                        {sample_question.image != '' &&

                            <img src={sample_question.image} style={{maxHeight:500, maxWidth:'100%', display:'block'}} />
                        }
                    </div>
                )
            }else{

                return (

                    <>
                        <Skeleton animation="wave" variant="rect" width={'80%'} height={45} style={{borderRadius:30}} />
                        <Skeleton animation="wave" variant="rect" width={'100%'} height={45} style={{borderRadius:30, marginTop:25}} />
                        <Skeleton animation="wave" variant="rect" width={'100%'} height={45} style={{borderRadius:30, marginTop:25}} />
                        <Skeleton animation="wave" variant="rect" width={'100%'} height={45} style={{borderRadius:30, marginTop:25}} />
                    </>
                )                
            }
        }
    }

    const renderOptions = () => {

        if(exam_started){

            if(category_exam){

                var rendered_questions = category_exam.rendered_questions;

                if(rendered_questions.hasOwnProperty(current_question)){

                    var question = (rendered_questions[current_question]);

                    return (

                        <>
                            {optionRow(question, 'A', 'option_1')}
                            {optionRow(question, 'B', 'option_2')}
                            {optionRow(question, 'C', 'option_3')}
                            {optionRow(question, 'D', 'option_4')}
                            {optionRow(question, 'E', 'option_5')}
                            {optionRow(question, 'F', 'option_6')}
                        </>
                    )
                }
            }
        }else{

            if(sample_question){

                return (

                    <>
                        {optionRow(sample_question, 'A', 'option_1')}
                        {optionRow(sample_question, 'B', 'option_2')}
                        {optionRow(sample_question, 'C', 'option_3')}
                        {optionRow(sample_question, 'D', 'option_4')}
                        {optionRow(sample_question, 'E', 'option_5')}
                        {optionRow(sample_question, 'F', 'option_6')}
                    </>
                )
            }
        }
    }

    const optionRow = (question, num, option) => {

        var is_selected = false;
        
        if(
            attempted_questions.hasOwnProperty(current_question)
        ){

            is_selected = attempted_questions[current_question];
        }

        if(selected != 0){

            is_selected = selected;
        }

        if(question[option] == '' && question[option + '_image'] == ''){

            return false;
        }

        return (

            <Card className={"exam-info-card option-card " + (is_selected == num ? 'selected' : '')} num={num} onClick={() => {

                setSelected(num);

                submitQuestion(num);
                
            }}>

                <CardContent>

                    <h4>{question[option]}</h4>
                    
                    {question[option + '_image'] != '' &&
                    
                        <img src={question[option + '_image']} style={{maxHeight:200, maxWidth:'100%', alignSelf:'center', flex:0}} />
                    }
                </CardContent>
            </Card>
        )
    }

    if(back){

        return <Redirect to="/account" />
    }

    return (

        <AccountWrapper page_title="Instructions" mode="full_screen">

            <FullScreen handle={handle} onChange={handleChanged}>
            
                <div className="exam-wrapper">

                    <div className="exam-header">
                        <div className="exam-header-container">

                            {exam_started
                                ?
                                    <>
                                        <h2>{exam.type_label} | <span>{category_exam.category_title}</span></h2>

                                        <div className="time">
                                            <span>Time Left:</span>
                                            
                                            <div className="countdown-timer ml-10">
                                                <Countdown
                                                    date={Date.now() + total_time}
                                                    zeroPadTime={2}
                                                    renderer={({hours, minutes, seconds}) => {

                                                        return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
                                                    }}
                                                    onComplete={() => {

                                                        console.log('completed');
                                                        setSubmitDialog(true);
                                                        setTimeOver(true);
                                                    }}
                                                    onTick={(t) => {
                                                        setTotalTime(t.total);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                :
                                    <>
                                        {sample_question
                                            ?
                                                <>
                                                    <h2>{exam.type_label} | <span>{exam.category_title}</span></h2>

                                                    <div className="time">
                                                        <div>
                                                            <span style={{fontSize:25}}>{category_exam.tasks_count}</span>
                                                            <span style={{marginLeft:5}}>Questions</span>
                                                        </div>
                                                        <div className="ml-10">
                                                            <span style={{fontSize:25}}>{category_exam.duration}</span>
                                                            <span style={{marginLeft:5}}>Minutes</span>
                                                        </div>
                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <Skeleton animation="wave" variant="rect" width={500} height={45} style={{borderRadius:30, backgroundColor:'rgba(255,255,255,.6)'}} />
                                                    <Skeleton animation="wave" variant="rect" width={200} height={44} style={{borderRadius:30, backgroundColor:'rgba(255,255,255,.6)'}} />
                                                </>
                                        }
                                    </>
                            }
                        </div>
                    </div>

                    {!submit_dialog &&
                        <div className="exam-action-bar">

                            <div className="exam-action-bar-block">

                                {exam_started
                                    ?
                                        <>
                                            <div>
                                                <span>Question:</span> <strong>{current_question} / {total_questions}</strong>
                                            </div>

                                            <Button color="secondary" variant="contained" onClick={(e) => {
                                                
                                                setSubmitDialog(true)
                                            }}>Submit Tests</Button>
                                        </>
                                    :
                                        <>
                                            {sample_question
                                                ?
                                                    <>
                                                        <h5>Instructions</h5>
                                                        <p>{sample_question.heading}</p>
                                                    </>
                                                :
                                                    <>
                                                        <Skeleton animation="wave" variant="rect" width={300} height={30} style={{borderRadius:30}} />
                                                        <Skeleton animation="wave" variant="rect" width={200} height={30} style={{borderRadius:30}} />
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    }

                    <div className="exam-body">

                        {submit_dialog
                            ?
                        
                                <TestReview
                                    attempted_questions={attempted_questions}
                                    total_questions={parseInt(total_questions)}
                                    time_over={time_over}
                                    onReview={() => {
                                        setSubmitDialog(false)
                                    }}
                                    onSubmit={() => {
                                        setSubmitDialog(false);
                                        submitExam();
                                    }}
                                />
                            :
                                <>

                                    <div className="exam-main">

                                        <div className="exam-container">
                                        
                                            <div className="exam-instructions">

                                                {renderQuestion()}
                                                
                                            </div>

                                        </div>
                                    </div>
                                    <div className="exam-sidebar">

                                        {exam_started
                                            ?

                                                <>

                                                    {renderOptions()}
                                                </>
                                            :
                                                <>
                                                
                                                    {sample_question
                                                        ?
                                                            <>
                                                                
                                                                {renderOptions()}
                                                                
                                                                <div className="mt-30">

                                                                    <div className="align-start mb-10">

                                                                        {
                                                                            sample_question.correct_option
                                                                            && 
                                                                            (sample_question.correct_option == 1 || sample_question.correct_option == 2 || sample_question.correct_option == 3 || sample_question.correct_option == 4)

                                                                            ?
                                                                                <>
                                                                                    <span>Answer:</span>
                                                                                    <span className="c-g ml-5">
                                                                                        {sample_question.correct_option == 1 ? '(A)' : ''}
                                                                                        {sample_question.correct_option == 2 ? '(B)' : ''}
                                                                                        {sample_question.correct_option == 3 ? '(C)' : ''}
                                                                                        {sample_question.correct_option == 4 ? '(D)' : ''}
                                                                                        {sample_question['option_' + sample_question.correct_option]}
                                                                                    </span>
                                                                                </>
                                                                            :
                                                                                null
                                                                        }
                                                                    </div>
                                                                    
                                                                    <div className="mt-20">
                                                                        {renderHTML(sample_question.instructions)}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        :
                                                            <>
                                                                <Skeleton animation="wave" variant="rect" width={'100%'} height={70} style={{borderRadius:20, backgroundColor:'rgba(255,255,255,.8)', marginBottom:15}} />
                                                                <Skeleton animation="wave" variant="rect" width={'100%'} height={70} style={{borderRadius:20, backgroundColor:'rgba(255,255,255,.8)', marginBottom:15}} />
                                                                <Skeleton animation="wave" variant="rect" width={'100%'} height={70} style={{borderRadius:20, backgroundColor:'rgba(255,255,255,.8)', marginBottom:15}} />
                                                                <Skeleton animation="wave" variant="rect" width={'100%'} height={70} style={{borderRadius:20, backgroundColor:'rgba(255,255,255,.8)', marginBottom:15}} />
                                                            </>
                                                    }
                                                </>
                                        }
                                        <div style={{marginBottom:200}}></div>
                                        
                                    </div>
                                </>
                        }
                    </div>
                </div>

                {!submit_dialog
                    ?
                        <div className="sticky-actions">

                            {exam_started
                                ?
                                    <div className={(parseInt(current_question) > 1 || parseInt(current_question) == parseInt(total_questions)) ? "space-between" : 'space-between'}>

                                        {current_question > 1
                                            ?
                                                <Button variant="outlined" color="primary" onClick={() => {

                                                    if(parseInt(current_question) > 1){

                                                        setCurrentQuestion( parseInt(current_question) - 1 );
                                                        setSelected(0);
                                                    }
                                                }} style={{color:'#fff', border:'1px solid #fff'}}>Back</Button>
                                            :
                                                null
                                        }

                                        {parseInt(current_question) < parseInt(total_questions)
                                            ?
                                                <Button style={{color:'rgba(255,255,255,.5)'}} onClick={() => {

                                                    setCurrentQuestion( parseInt(current_question) + 1 );
                                                    setSelected(0);
                                                }}>Skip</Button>
                                            :
                                                null
                                        }

                                        {/* {selected != 0
                                            ?
                                                <Button variant="contained" color="primary" onClick={() => {

                                                    submitQuestion()
                                                }}>Submit Answer</Button>
                                            :
                                                null
                                        } */}
                                    </div>
                                :
                                    <div className="flex-center">
                                        <Button variant="contained" onClick={() => {

                                            if(exams_count <= 0){
                                            
                                                setOpen(true);
                                            }else{

                                                startExam(true, false, false);
                                                setCurrentQuestion(1);
                                                
                                                if(!handle.active){

                                                    handle.enter();
                                                }
                                            }
                                        }} color="primary">Start Exam</Button>
                                    </div>
                            }

                            <Loader loading={submitting} />
                        </div>
                    :
                        null
                }

                <Dialog open={open} onClose={() => {
                
                }} aria-labelledby="form-dialog-title">
                    
                    <DialogTitle id="form-dialog-title">
                        <div className="align-start">
                            <ReportProblemIcon className="c-r" />
                            <span className="ml-10 c-r">Alert</span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>Your exam will be conducted in full screen mode.</DialogContentText>
                        <DialogContentText>Exam will be submitted automatically if you press "esc" or exit the full screen mode!</DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => {
                            
                            setOpen(false);
                            handle.enter();
                            startExam(true, false, false);
                            setCurrentQuestion(1);

                        }} color="primary" variant="contained">
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>

            </FullScreen>
        </AccountWrapper>
    )

    function startExam(init, submit, current_answer){

        const formData = new FormData();

        formData.append('sf_student_token', sf_student_token);
        formData.append('exam_id', category_exam.row_id);

        if(!init && !submit){

            if(category_exam){

                var rendered_questions = category_exam.rendered_questions;

                if(rendered_questions.hasOwnProperty(current_question)){

                    var question = (rendered_questions[current_question]);
                    formData.append('question', question.id);
                    formData.append('answer', current_answer);

                    setSubmitting(true);
                }
            }
        }

        if(init){

            formData.append('init', true);
        }

        if(submit){

            formData.append('submit', true);
        }

        Api.post('students/update_exam', formData, function(data){

            if(data.status){

                if(!init && !submit){

                    setSubmitting(false);

                    //Update local info   
                    attempted_questions[current_question] = current_answer;
                        
                    setAttemptedQuestions(attempted_questions);

                    if(parseInt(total_questions) > parseInt(current_question)){

                        setCurrentQuestion( parseInt(current_question) + 1 );
                        setSelected(0);
                    }else if(parseInt(total_questions) == parseInt(current_question)){

                        // If last answer then show review
                        setSubmitDialog(true);
                    }
                }
                
                if(init){
                
                    setExamStarted(true);
                    setSelected(0);
                }

                if(submit){

                    setSelected(0);
                    if(data.pending_exams > 0){
                    
                        loadNextExam();
                    }else{

                        setBack(true);
                    }
                }
            }else{

                
            }
        });
    }

    function loadNextExam(){

        setExamStarted(false);
        setCategoryExam(false);
        setCurrentQuestion(0);
        setAttemptedQuestions({});
        setExam(false);
        setSampleQuestion(false);
        setSelected(0);
        setSubmitDialog(false);

        loadExam(sf_student_token, exam_id, true);
    }

    function submitQuestion(answer){

        startExam(false, false, answer);
    }

    function submitExam(){

        startExam(false, true, false);
    }

    function loadExam(sf_student_token, exam_id, next_attempt){

        const formData = new FormData();

        setLoader(true);

        formData.append('sf_student_token', sf_student_token);
        formData.append('exam_id', exam_id);

        Api.post('students/load_exam', formData, function(data){
            
            if(data.status){

                setExamsCount(data.exams_count);
                setExam(data.exam);
                setCategoryExam(data.category_exam);

                setTotalTime(data.category_exam.duration_processed);

                setTotalQuestions(Object.keys(data.category_exam.rendered_questions).length);

                if(data.sample_question){
                
                    setSampleQuestion(data.sample_question);

                    var _option_values = {1: 'A', 2: 'B', 3: 'C', 4: 'D'};
                    setSelected(_option_values[data.sample_question.correct_option]);
                    setLoader(false);
                }else{

                    if(!next_attempt){
                    
                        setOpen(true);
                    }
                }

            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    localStorage.setItem('temp_error', data.message);
                    setBack(true);
                }
            }
        });
    }
}

export default TestScreen;