import React, { Component } from 'react';


class AuthSidebar extends Component { 
    constructor(props) {
        super();
        this.state = {}
    }

    render () {

        return (

            <div className="left half-container patterned-bg">
                <div className="text-block">
                </div>
            </div>
        )
    }
}

export default AuthSidebar;