import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Api from '../../api/Api';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

export default class Logout extends Component { 
    constructor(props) {
        super();
        this.state = {
            redirect: false
        }
    }

    componentDidMount = () => {
        localStorage.setItem('is_logged_in', 0);
        localStorage.removeItem('sf_student_token');
        localStorage.removeItem('user');
        localStorage.removeItem('user_token');
        localStorage.removeItem('account_token');

        window.location.href = Api.url;
        this.setState({redirect: true});
    }
    
    render () {
        return (
            <></>
        )
    }
}