import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Grid, FormControl, TextField, InputAdornment, Button, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import { Alert, AlertTitle } from '@material-ui/lab';

import { EmailOutlined, LockOutlined } from '@material-ui/icons';

import { connect } from 'react-redux';
import {User} from '../../actions/user';

import Api from '../../api/Api';

import ValidatorHelper from '../../helpers/ValidatorHelper';
import LayoutHelper from '../../helpers/LayoutHelper';

import Main from '../../components/Main';

import AuthSidebar from './AuthSidebar';

class ForgotPassword extends Component { 
    constructor(props) {
        super();
        this.state = {

            dashboard: false,
            login: false,

            loading: false,

            error_message: '',
            success_message: '',

            email: '',
            
            email_error: '',
            
            status_error: '',
            status_success: '',

            user: false,
            user_token: false,

            redirect: false,
            logout: false
        }
    }

    componentDidMount = () => {

        var user_token = localStorage.getItem('user_token');

        if(user_token){

            this.setState({redirect: true})
        }
    }

    render () {

        if(this.state.redirect){
            return <Redirect to="/dashboard" />
        }

        if(this.state.logout){
            return <Redirect to="/logout" />
        }

        return (

            <Main sidebar={false} full_width={true} className="loginpage" success_mesasge={this.state.success_mesasge} error_message={this.state.error_message}>
                <div page="login" className="login-main-page">
                    <div className="container">
                        <div className="login-page d-flex items-end">
                            
                            <AuthSidebar />

                            <div className="right half-container self-right auth-block" style={{position:'relative'}}>

                                <div className="section-heading">Forgot Password</div>

                                {this.state.status_success &&
                                    <Alert severity="success" className="mb-30" onClose={() => {this.setState({status_success: false})}}>
                                        <AlertTitle>Success</AlertTitle>
                                        {this.state.status_success}
                                    </Alert>
                                    
                                }

                                {this.state.status_error &&
                                    <Alert severity="error" className="mb-30" onClose={() => {this.setState({status_error: false})}}>
                                        <AlertTitle>Error</AlertTitle>
                                        {this.state.status_error}
                                    </Alert>
                                }

                                <form onSubmit={this.loginSubmit.bind(this)} id="register_form">
                                    <Grid container>
                                        <Grid className="mb-30" item xs={12} sm={12}>

                                            <FormControl fullWidth={true}>
                                                <TextField value={this.state.email} onChange={(v) => {
                                                    this.setState({email: v.target.value})
                                                }} className="filled-input" name="email" placeholder="Email Address" variant="outlined" InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EmailOutlined />
                                                        </InputAdornment>
                                                    ),
                                                }} error={this.state.email_error} helperText={this.state.email_error ? 'Please enter valid email' : ''} onBlur={(e) => {
                                                    if(e.target.value != '' && ValidatorHelper.validEmail(e.target.value)){
                                                        this.setState({email_error: false})
                                                    }
                                                }} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>

                                            <div className="space-between">

                                                <div className="align-center" style={{position:'relative'}}>
                                                    <Button type="submit" variant="contained" color="primary">Submit</Button>
                                                    {this.state.loading && <CircularProgress size={24} />}
                                                </div>

                                                <Link className="ml-10" to="/signin">Back to login</Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        )
    }

    loginSubmit = (event) => {

        event.preventDefault();
        const formData = new FormData(event.target);

        var _has_error = false;

        var email = formData.get('email');

        if(email == '' || !ValidatorHelper.validEmail(email)){
            this.setState({email_error: true});
            _has_error = true;
        }else{
            this.setState({email_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(!_has_error){

            var that = this;
            this.setState({loading: true});
        
            Api.post('auth/forgot_password', formData, function(data){
            
                that.setState({loading: false, email: ''});

                if(data.status == true){
                    
                    that.setState({status_success: data.message});

                }else{

                    LayoutHelper.addErrorMessage(that, data.message);
                }
            });
        }
    }
}

const mapStateToProps = state => {
	return {
        user: state.user.user
	}
}

export default connect(mapStateToProps, { User } )(ForgotPassword);
