import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { FormControl, TextField, Checkbox, FormGroup, FormControlLabel, Button, CircularProgress } from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

import Main from '../../components/Main';

import ValidatorHelper from '../../helpers/ValidatorHelper';

import AuthSidebar from './AuthSidebar';

function RemebermeCheckbox() {
    const [checked, setChecked] = React.useState(true);
  
    const handleChange = (event) => {
      setChecked(event.target.checked);
    };
  
    return (
        <FormControlLabel control={<Checkbox checked={checked} name="remember" onChange={handleChange} />} label="keep me logged in" />
    );
}

class Signin extends Component { 
    constructor(props) {
        super();
        this.state = {
            loading: false,
            login: false,
            success_message: false,
            error_message: false,

            showPassword: false,
            rememberme_checked: false
        }
    }

    componentDidMount = () => {
        var account_token = localStorage.getItem('sf_student_token');
        
        if(account_token){
            this.setState({login: true});
        }
    }

    render () {
        if(this.state.login){
            return <Redirect to='/account' />;
        }

        return (

            <Main page="loginpage" className="loginpage">
                <div page="login" className="login-main-page">
                    <div className="container">
                        <div className="login-page d-flex items-end">
                            
                            <AuthSidebar />
                            <div className="right half-container self-right auth-block" style={{position:'relative'}}>

                                <div className="section-heading">Login</div>

                                {/* using if statement */}
                                {this.state.success_message &&
                                    <Alert severity="success" className="mb-30" onClose={() => {this.setState({success_message: false})}}>
                                        <AlertTitle>Success</AlertTitle>
                                        {this.state.success_message}
                                    </Alert>
                                    
                                }

                                {this.state.error_message &&
                                    <Alert severity="error" className="mb-30" onClose={() => {this.setState({error_message: false})}}>
                                        <AlertTitle>Error</AlertTitle>
                                        {this.state.error_message}
                                    </Alert>
                                }
                                
                                <form className="form" name="login_form" onSubmit={this.onFinish.bind(this)} autoComplete="off">
                                    
                                    <FormControl className="form-control">
                                        <TextField name="email" label="Email" variant="outlined" />
                                    </FormControl>

                                    <FormControl className="form-control">
                                        <TextField label="Password" type="password" name="password" variant="outlined" />
                                    </FormControl>
                                    
                                    <FormControl className="form-control">
                                        <FormGroup className="d-flex remember-forgot">
                                            <RemebermeCheckbox />

                                            <Link to="/forgot-password">Forgot Password</Link>
                                        </FormGroup>
                                    </FormControl>

                                    <Button className="btn btn-red" type="submit" variant="contained">Login</Button>
                                </form>

                                <div className="text-center signup-link">
                                    Don't have an account? <Link to="/signup" className="red-txt">Sign up</Link>
                                </div>

                                <Loader loading={this.state.loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        )
    }

    onFinish = (event) => {

        event.preventDefault();

        const formData = new FormData(event.target);

        var _has_error = false;

        var email = formData.get('email');
        var password = formData.get('password');

        if(email == '' || !ValidatorHelper.validEmail(email)){
            this.setState({email_error: true});
            _has_error = true;
        }else{
            this.setState({email_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(password == '' || password.length < 6){
            this.setState({password_error: true});
            _has_error = true;
        }else{
            this.setState({password_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(!_has_error){
        
            var that = this;

            this.setState({loading: true});

            Api.post('auth/login', formData, function(data){
                
                if(data.status == true){
                
                    localStorage.setItem('sf_student_token', data.account_token);
                    localStorage.setItem('user', JSON.stringify(data.user));
                    
                    that.setState({loading: false});

                    that.setState({success_message: data.message});
                    window.setTimeout(() => {
                        that.setState({login: true});
                    }, 500);
                
                }else{
                    that.setState({loading: false});
                    that.setState({error_message: data.message});
                }
                window.setTimeout(() => {
                    that.setState({success_message: false});
                    that.setState({error_message: false});
                }, 10000);
            });
        }
    }
}

export default Signin;