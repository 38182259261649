import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, Paper, AppBar, Card, CardContent, CardMedia } from '@material-ui/core';

import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import AccountWrapper from '../AccountWrapper';

class Tests extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <AccountWrapper page_title="Tests">

                <Link to="/account/test/instructions">Start Exam</Link>
            </AccountWrapper>
        )
    }
}

export default Tests;