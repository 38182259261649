import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { Container } from '@material-ui/core';

import footerlogo from '../assets/images/footer-logo.png';
import gmap from '../assets/images/gmap.png';

class AppFooter extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        this.setState({account_token: account_token});

    }

    render () {

        return (

            <React.Fragment>
                <div className="footer">
                    <Container>
                        <div className="footer-block">
                            <div className="block">
                                <div className="footer-logo">
                                    <img className="logo" src={footerlogo} alt="Logo" />
                                </div>
                                <div className="email">info@studyfeeds.com</div>
                                <div className="heading txt-white">Follow Us:</div>
                                <ul className="list-inline d-flex social-icons">
                                    <li><Link to={{ pathname: "https://www.facebook.com/studyfeeds/" }} target="_blank"><i className="ico facebook"></i></Link></li>
                                    <li><Link to={{ pathname: "https://twitter.com/studyfeeds/" }} target="_blank"><i className="ico twitter"></i></Link></li>
                                    <li><Link to={{ pathname: "https://www.youtube.com/channel/UCE63iwDEr6zHxSz9MOJCAyQ" }} target="_blank"><i className="ico youtube"></i></Link></li>
                                    <li><Link to={{ pathname: "https://www.instagram.com/studyfeeds.in/" }} target="_blank"><i className="ico instagram"></i></Link></li>
                                </ul>
                            </div>
                            <div className="block">
                                <div className="heading">Explore</div>
                                <ul className="list-inline">
                                    <li><Link to="/testas">TestAs</Link></li>
                                    <li><Link to="/german-language">German Language</Link></li>
                                    <li><Link to="/">Why Us</Link></li>
                                    <li><Link to="/terms-of-use">Terms of Use</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-of-use">Refund and Cancellations</Link></li>
                                </ul>
                            </div>
                            <div className="block">
                                <div className="heading">Address</div>
                                <div className="address-text">
                                    G 59, Marina Arcade, Middle Circle,<br />
                                    Connaught Place, New Delhi – 110001<br />
                                    <span>Nearest Metro – Rajiv Chowk, Gate No. 7</span>
                                </div>

                                <div className="gmap">
                                    <iframe frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?q=G%2059%2C%20Marina%20Arcade%2C%20Middle%20Circle%2C%20Connaught%20Place%2C%20New%20Delhi%20%E2%80%93%20110001&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="G 59, Marina Arcade, Middle Circle, Connaught Place, New Delhi – 110001" aria-label="G 59, Marina Arcade, Middle Circle, Connaught Place, New Delhi – 110001"></iframe>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                
            </React.Fragment>
        )
    }
}

export default AppFooter;