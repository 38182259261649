import React, { Component } from 'react';

import Main from '../../components/Main';

import '../../assets/styles/exam.css';

class AccountWrapper extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <Main mode={this.props.mode ? this.props.mode : null} success_message={this.props.success_message} error_message={this.props.error_message}>
                <div className="account-wrapper">

                    {this.props.children}
                </div>
            </Main>
        )
    }
}

export default AccountWrapper;