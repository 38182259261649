import React, { Component } from 'react';

import { Redirect } from "react-router-dom";
import { Container, FormGroup, FormControl, FormControlLabel, TextField, Checkbox, Button } from '@material-ui/core';

import bannerimage from '../../assets/images/other-banner.png';

import { Alert, AlertTitle } from '@material-ui/lab';

import Loader from '../../components/Loader';
import Api from '../../api/Api';

import ValidatorHelper from '../../helpers/ValidatorHelper';

function AgreeCheckbox() {
    const [checked, setChecked] = React.useState(true);
  
    const handleChange = (event) => {
      setChecked(event.target.checked);
    };
  
    return (
        <FormControlLabel control={<Checkbox checked={checked} name="agree" onChange={handleChange} />} label="I agree and affirm that Study Feeds may contact me and communicate with me over phone or email." />
    );
}

class Bannerwithform extends Component { 
    constructor(props) {
        super();
        this.state = {

            success_message: false,
            error_message: false,

            first_name: '',
            last_name: '',
            email: '',
            mobile_no: '',
            message: '',

            first_name_error: '',
            last_name_error: '',
            email_error: '',
            mobile_no_error: '',
            message_error: '',

            redirect: false
        }
    }

    render () {

        if(this.state.redirect != ''){

            return <Redirect to={"/thank-you/" + this.state.redirect} />
        }

        return (
            <div className="banner-slider">
                <div className="banners-block half-form plus">
                    <div className="bg-block">
                        <img className="banner-image" src={bannerimage} alt="Banner Image" />
                    </div>
                    <Container>
                        <div className="block">
                            <div className="head-text sm ">{this.props.title}</div>
                            {this.props.subtitle && this.props.subtitle != ''
                                ?
                                    <div className="subhead-text">{this.props.subtitle}</div>
                                :
                                    null
                            }
                            <div className="description">{this.props.content}</div>
                        </div>
                        <div className="block">

                            <div style={{position:'relative'}}>
                                <form className="form" name="enquiry_form" onSubmit={this.onFinish.bind(this)} autoComplete="off">
                                    <div className="title">Admission in Germany</div>
                                    <div className="subtitle">Enquiry</div>

                                    {/* using if statement */}
                                    {this.state.success_message &&
                                        <Alert severity="success" className="mb-30" onClose={() => {this.setState({success_message: false})}}>
                                            <AlertTitle>Success</AlertTitle>
                                            {this.state.success_message}
                                        </Alert>
                                    }

                                    {this.state.error_message &&
                                        <Alert severity="error" className="mb-30" onClose={() => {this.setState({error_message: false})}}>
                                            <AlertTitle>Error</AlertTitle>
                                            {this.state.error_message}
                                        </Alert>
                                    }

                                    <FormGroup className="d-flex">
                                        <FormControl className="form-control">
                                            <TextField
                                                name="first_name"
                                                label="First Name"
                                                variant="outlined"
                                                value={this.state.first_name}
                                                onChange={(e) => {

                                                    this.setState({first_name: e.target.value});
                                                    if(e.target.value != ''){

                                                        this.setState({first_name_error: ''})
                                                    }
                                                }}
                                                error={this.state.first_name_error}
                                                helperText={this.state.first_name_error ? 'First Name is required' : ''}
                                            />
                                        </FormControl>

                                        <FormControl className="form-control">
                                            <TextField
                                                name="last_name"
                                                label="Last Name"
                                                variant="outlined"
                                                error={this.state.last_name_error}
                                                helperText={this.state.last_name_error ? 'Last Name is required' : ''}
                                                value={this.state.last_name}
                                                onChange={(e) => {

                                                    this.setState({last_name: e.target.value});
                                                    if(e.target.value != ''){

                                                        this.setState({last_name_error: ''})
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                        
                                    <FormGroup className="d-flex">
                                        <FormControl className="form-control">
                                            <TextField
                                                name="email"
                                                label="Email"
                                                variant="outlined"
                                                error={this.state.email_error}
                                                helperText={this.state.email_error ? 'Enter valid email' : ''}
                                                value={this.state.email}
                                                onChange={(e) => {

                                                    this.setState({email: e.target.value});

                                                    if(e.target.value != '' && ValidatorHelper.validEmail(e.target.value)){

                                                        this.setState({email_error: ''})
                                                    }
                                                }}
                                            />
                                        </FormControl>

                                        <FormControl className="form-control">
                                            <TextField
                                                name="mobile_no"
                                                label="Mobile No."
                                                variant="outlined"
                                                error={this.state.mobile_no_error}
                                                helperText={this.state.mobile_no_error ? 'Enter valid mobile number' : ''}
                                                value={this.state.mobile_no}
                                                onChange={(e) => {

                                                    this.setState({mobile_no: e.target.value});

                                                    if(e.target.value != '' && !isNaN(e.target.value) && e.target.value.length >= 10){

                                                        this.setState({mobile_no_error: ''})
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </FormGroup>

                                    <FormControl className="form-control">
                                        <TextField
                                            name="message"
                                            label="Message"
                                            variant="outlined"
                                            multiline
                                            rows={2}
                                            error={this.state.message_error}
                                            helperText={this.state.message_error ? 'Message is required' : ''}
                                            value={this.state.message}
                                            onChange={(e) => {

                                                this.setState({message: e.target.value});

                                                if(e.target.value != ''){

                                                    this.setState({message_error: ''})
                                                }
                                            }}
                                        />
                                    </FormControl>

                                    <FormControl className="form-control">
                                        <AgreeCheckbox />
                                    </FormControl>
                                    
                                    <Button className="btn btn-red" type="submit" variant="contained">Enquire Now</Button>

                                    <Loader loading={this.state.loading} />
                                </form>
                                
                            </div>
                            
                        </div>
                    </Container>
                </div>
            </div>
        )
    }

    onFinish = (event) => {

        event.preventDefault();

        const formData = new FormData(event.target);

        var _has_error = false;

        var first_name = formData.get('first_name');
        var last_name = formData.get('last_name');
        var email = formData.get('email');
        var mobile_no = formData.get('mobile_no');
        var message = formData.get('message');

        if(first_name == ''){
            this.setState({first_name_error: true});
            _has_error = true;
        }else{
            this.setState({first_name_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(last_name == ''){
            this.setState({last_name_error: true});
            _has_error = true;
        }else{
            this.setState({last_name_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(email == '' || !ValidatorHelper.validEmail(email)){
            this.setState({email_error: true});
            _has_error = true;
        }else{
            this.setState({email_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(mobile_no == '' || mobile_no.length < 10){
            this.setState({mobile_no_error: true});
            _has_error = true;
        }else{
            this.setState({mobile_no_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(message == ''){
            this.setState({message_error: true});
            _has_error = true;
        }else{
            this.setState({message_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(!_has_error){
        
            var that = this;

            this.setState({loading: true});

            if(this.props.page){

                formData.append('page_source', this.props.page);
            }

            Api.post('enquiry', formData, function(data){
                
                if(data.status == true){
                
                    that.setState({loading: false, redirect: data.id});

                    that.setState({success_message: data.message});

                    that.setState({
                        first_name: '',
                        last_name: '',
                        email: '',
                        mobile_no: '',
                        message: ''
                    });
                
                }else{
                    that.setState({loading: false});
                    that.setState({error_message: data.message});
                }
                window.setTimeout(() => {
                    that.setState({success_message: false});
                    that.setState({error_message: false});
                }, 10000);
            });
        }
    }
}

export default Bannerwithform;