import React from 'react';

import './assets/styles/theme.css';

import {BrowserRouter, Route, useParams, Switch} from 'react-router-dom';

import Homepage from './pages/Homepage';
import TestAs from './pages/TestAs';
import AboutTestAs from './pages/AboutTestAs';
import GermanLanguage from './pages/GermanLanguage';
import SubjectChoices from './pages/SubjectChoices';
import DuringStudyGermany from './pages/DuringStudyGermany';
import AfterStudy from './pages/AfterStudy';
import PreviousProcedure from './pages/PreviousProcedure';
import BlockedAccount from './pages/BlockedAccount';
import AboutStudyFeeds from './pages/AboutStudyFeeds';

import EnquirySuccess from './pages/pages/EnquirySuccess';

import TermsConditions from './pages/pages/TermsConditions';
import PrivacyPolicy from './pages/pages/PrivacyPolicy';

import Contact from './pages/pages/Contact';
import Signin from './pages/auth/Signin';
import Signup from './pages/auth/Signup';
import EmailVerification from './pages/auth/EmailVerification';
import PendingVerification from './pages/auth/PendingVerification';
import Logout from './pages/auth/Logout';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';

import Account from './pages/account/Account';

import Tests from './pages/account/tests/Tests';
import TestInstructions from './pages/account/tests/TestInstructions';
import TestScreen from './pages/account/tests/TestScreen';
import TestsList from './pages/account/tests/TestsList';

import EligibilityForm from './pages/EligibilityForm';
import EligibilitySuccess from './pages/pages/EligibilitySuccess';

import ScrollToTop from './components/ScrollToTop';

function App() {
    return (
        
        <BrowserRouter basename={'/'}>

            <ScrollToTop />
            
            <Route exact={true} path='/' render={() => (
                <Homepage />
            )} />

            <Route exact={true} path='/testas' render={() => (
                <TestAs />
            )} />

            <Route exact={true} path='/about-testas' render={() => (
                <AboutTestAs />
            )} />

            <Route exact={true} path='/german-language' render={() => (
                <GermanLanguage />
            )} />

            <Route exact={true} path='/subject-choices' render={() => (
                <SubjectChoices />
            )} />

            <Route exact={true} path='/during-study-in-germany' render={() => (
                <DuringStudyGermany />
            )} />

            <Route exact={true} path='/after-study' render={() => (
                <AfterStudy />
            )} />

            <Route exact={true} path='/previous-procedure' render={() => (
                <PreviousProcedure />
            )} />

            <Route exact={true} path='/blocked-account' render={() => (
                <BlockedAccount />
            )} />

            <Route exact={true} path='/about-studyfeeds' render={() => (
                <AboutStudyFeeds />
            )} />

            <Route exact={true} path='/terms-of-use' render={() => (
                <TermsConditions />
            )} />

            <Route exact={true} path='/privacy-policy' render={() => (
                <PrivacyPolicy />
            )} />

            <Route exact={true} path='/contact' render={() => (
                <Contact />
            )} />

            <Route exact={true} path='/eligibility-form/:request_id?' render={(props) => (
                <EligibilityForm {...props.match.params} />
            )} />

            <Route exact={true} path='/thank-you/:row_id?' render={(props) => (
                <EnquirySuccess {...props.match.params} />
            )} />

            <Route exact={true} path='/thankyou' render={() => (
                <EligibilitySuccess />
            )} />

            <Route exact={true} path='/account' render={() => (
                <Account />
            )} />

            <Route exact={true} path='/account/tests' render={() => (
                <TestsList />
            )} />

            {/* <Route exact={true} path='/account/test/instructions/:exam_id?/:type?' render={(props) => (
                <TestInstructions {...props.match.params} />
            )}/> */}

            <Route exact={true} path='/account/test/instructions/:type?' render={(props) => (
                <TestInstructions {...props.match.params} />
            )}/>

            <Route exact={true} path='/account/test/running/:exam_id?' render={(props) => (
                <TestScreen {...props.match.params} />
            )} />

            <Route exact={true} path='/signup' render={() => (
                <Signup />
            )} />

            <Route exact={true} path='/signin' render={() => (
                <Signin />
            )} />

            <Route exact={true} path='/email-verified' render={() => (
                <div className="email-verified">
                    <EmailVerification />
                </div>
            )}/>

            <Route exact={true} path="/pending-verification/:user_id?" children={<PendingVerificationRoute />} />

            <Route exact={true} path='/logout' render={() => (
                <Logout />
            )} />

            <Route exact={true} path="/forgot-password" children={<ForgotPassword />} />

            <Route exact={true} path='/reset-password/:code?' render={(props) => (
                <div className="reset-password">
                    <ResetPassword {...props.match.params} />
                </div>
            )} />

        </BrowserRouter>
    );
}

function PendingVerificationRoute(){
    let { user_id } = useParams();
    
    return (
        <div className="app-container">
            <PendingVerification user_id={user_id} />
        </div>
    )
}

export default App;
