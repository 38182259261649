import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import Bannerwithform from '../components/sections/Bannerwithform';

import Main from '../components/Main';

class GermanLanguage extends Component { 
    constructor(props) {
        super();
        this.state = {
        }
    }

    render () {

        return (

            <Main page="test_as_main" className="german-language">
                <Bannerwithform page="enquiry" title="German Language" />

                <div className="section german-lang-section">
                    <Container>
                        <div className="main-section d-flex">
                            <div className="block">
                                <div className="text">The German language is divided into the following CEFR levels, namely:</div>

                                <List className="options-listing half" component="nav" aria-label="main options">
                                    <ListItem>
                                        <ListItemText primary="A1" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="B2.2" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="A2" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="C1" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="B1" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="C2" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="B2.1" />
                                    </ListItem>
                                </List>
                            </div>

                            <div className="block">
                                <div className="text">The following examinations are conducted by the given organizations to certify the German language skills:</div>

                                <List className="options-listing" component="nav" aria-label="main options">
                                    <ListItem>
                                        <ListItemIcon>1.</ListItemIcon>
                                        <ListItemText primary="TestDaF by G.A.S.T. e.V. – Common exam for all the levels." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>2.</ListItemIcon>
                                        <ListItemText primary="Goethe across different levels." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>3.</ListItemIcon>
                                        <ListItemText primary="TELC – Common exam for all the levels." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>4.</ListItemIcon>
                                        <ListItemText primary="DSH conducted by the German Universities – Common exam for all the levels., etc. " />
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    </Container>
                </div>

            </Main>
        )
    }
}

export default GermanLanguage;