import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { makeStyles, Container, Grid, Paper, AppBar, Card, CardContent, Button } from '@material-ui/core';

import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class TestReview extends Component { 
    constructor(props) {
        super();
        this.state = {

            calculated: false,
            percentage: 0
        }
    }

    componentDidMount = () => {

        window.setTimeout(() => {

            var attempted_questions = this.props.attempted_questions;
            var total_questions = this.props.total_questions

            if(
                !isNaN(parseInt(total_questions))
                &&
                !isNaN(Object.keys(attempted_questions).length)
            ){
            
                var percentage = (parseInt(Object.keys(attempted_questions).length) / parseInt(total_questions)) * 100;

                this.setState({percentage: percentage, calculated: true});
            }
        }, 500);
    }

    render () {

        var percentage = this.state.percentage;

        return (

            <div className="exam-review">

                <div className="exam-review-progress">
                    <CircularProgressbar
                        styles={{
                            path: {
                                stroke: `rgba(67, 197, 35, 1)`,
                            },
                            trail: {
                                stroke: 'rgba(0,0,0,.01)',
                            }
                        }}
                        strokeWidth={6}
                        value={percentage}
                        text={(Object.keys(this.props.attempted_questions).length) + ' / ' + this.props.total_questions}
                    />
                </div>
                <div className="exam-review-content">
                    
                    <h5>Finished!</h5>

                    {!this.props.time_over &&
                        
                        <>
                            {(this.state.calculated && this.state.percentage < 100)
                                ?
                                    <strong>It seems that you have left few questions.</strong>
                                :
                                    null
                            }
                        </>
                    }

                    {!this.props.time_over
                        ?
                            <p>If you like to attempt the remaining, click on the <span style={{color:'#E26A27'}}>review</span> button or jump to the next sub-test by clicking on the <span style={{color:'#1FB425'}}>submit</span> button.</p>
                        :
                            <p>Jump to the next sub-test by clicking on the <span style={{color:'#1FB425'}}>submit</span> button.</p>
                    }

                    <div className="action-sm">

                        {!this.props.time_over &&
                            <Button className="btn btn-red-light" onClick={() => {
                                this.props.onReview()
                            }}>Review</Button>
                        }
                        
                        <Button className="btn btn-success ml-30" onClick={() => {
                            this.props.onSubmit();
                        }}>Submit</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default TestReview;